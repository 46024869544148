
export var MeasureEvents = {

  MEASUREMENT_CHANGED_EVENT: 'measurement-changed',
  UNITS_CALIBRATION_STARTS_EVENT: 'units_calibration_starts_event',
  FINISHED_CALIBRATION_FOR_DIMENSION_EVENT: 'finished_calibration_for_dimension_event',
  CALIBRATION_REQUIRED_EVENT: 'calibration-required',
  OPEN_CALIBRATION_PANEL_EVENT: 'open-calibration-panel',
  CLOSE_CALIBRATION_PANEL_EVENT: 'close-calibration-panel',
  CLEAR_CALIBRATION_SIZE_EVENT: 'clear-calibration-size',
  FINISHED_CALIBRATION: 'finished-calibration',
  DISPLAY_UNITS_CHANGED: 'display-units-changed' };