import { getHFDM } from "../workers/HfdmWorker";
import { parseHfdmUrl } from "./parseHfdmUrl";

/**
                                                * Initialize the hfdm ViewingService
                                                * @param {*} ViewingService The non-hfdm ViewingService
                                                */
export function initialize(ViewingService) {
  // Hook into rawGet after throttling if an. NodeJS and browasers are different
  var _rawGet = ViewingService.rawGet; // Save original raw get

  /**
   * Get the content of a binary property
   * @param {string} viewingServiceBaseUrl - The base url for the viewing service.
   * @param {ParsedId} parsedUrl - The parsed id to the binary property
   * @param {string} id - The id of the binary property
   * @param {string} url - The url for the request.
   * @param {function} onSuccess - A function that takes a single parameter that represents the response
   *                               returned if the request is successful.
   * @param {function} onFailure - A function that takes an integer status code, and a string status, which together represent
   *                               the response returned if the request is unsuccessful, and a third data argument, which
   *                               has more information about the failure.  The data is a dictionary that minimally includes
   *                               the url, and an exception if one was raised.
   * @param {Object=} [options] - A dictionary of options that can include:
   *                              headers - A dictionary representing the additional headers to add.
   *                              queryParams - A string representing the query parameters
   *                              responseType - A string representing the response type for this request.
   *                              {boolean} [encodeUrn] - when true, encodes the document urn if found.
   *                              {boolean} [noBody] - when true, will perform a HEAD request
   */
  function getDataContent(viewingServiceBaseUrl, parsedUrl, id, onSuccess, onFailure, options) {

    // In v2, only second part of the branch urn should be used to get the sigend url
    // for e.g., if workspace branch urn is urn:adsk.hfdmstg:hfdm.branch:74212dad-7153-86ca-2a70-fba5b4955196/025b4cf5-371e-2b6d-f0fd-0c6e1b469a37
    // to get he signd urn, we should use only the second guid 025b4cf5-371e-2b6d-f0fd-0c6e1b469a37
    var branchguid = parsedUrl.branch.substr(parsedUrl.branch.lastIndexOf(":") + 1);
    branchguid = branchguid.substr(branchguid.indexOf('/') + 1);
    var restUrl = '/v2/signedUrl/branch/' + branchguid + '/action/getObject';
    var signedOptions = {
      responseType: "json",
      queryParams: "key=" + encodeURIComponent(id) + "&response-content-type=" + encodeURIComponent("application/octet-stream"),
      headers: options && options.headers };

    if (options && options.hasOwnProperty("withCredentials"))
    signedOptions.withCredentials = options.withCredentials;
    _rawGet(viewingServiceBaseUrl, "", restUrl, function (response) {
      var getOptions = {
        withCredentials: false,
        ondata: options.ondata };

      if (options && options.hasOwnProperty("responseType"))
      getOptions.responseType = options.responseType;
      var url = response.url;
      var queryParams = url.indexOf("?");
      if (queryParams !== -1) {
        getOptions.queryParams = url.substr(queryParams + 1);
        url = url.substr(0, queryParams);
      }
      // console.log("Response URL: " + response.url);
      _rawGet(viewingServiceBaseUrl, "", url, onSuccess, onFailure, getOptions);
    }, onFailure, signedOptions);
  }

  /**
     *  Performs a GET/HEAD request to Viewing Service. This function replaces the original
     *
     * @param {string} viewingServiceBaseUrl - The base url for the viewing service.
     * @param {string} api - The api to call in the viewing service.
     * @param {string} url - The url for the request.
     * @param {function} onSuccess - A function that takes a single parameter that represents the response
     *                               returned if the request is successful.
     * @param {function} onFailure - A function that takes an integer status code, and a string status, which together represent
     *                               the response returned if the request is unsuccessful, and a third data argument, which
     *                               has more information about the failure.  The data is a dictionary that minimally includes
     *                               the url, and an exception if one was raised.
     * @param {Object=} [options] - A dictionary of options that can include:
     *                              headers - A dictionary representing the additional headers to add.
     *                              queryParams - A string representing the query parameters
     *                              responseType - A string representing the response type for this request.
     *                              {boolean} [encodeUrn] - when true, encodes the document urn if found.
     *                              {boolean} [noBody] - when true, will perform a HEAD request
     */
  ViewingService.rawGet = function (viewingServiceBaseUrl, api, url, onSuccess, onFailure, options) {
    // Only use the HFDM path, if we are using the hfdm api flavor and the url has a URN in it
    // and we can parse the URL.
    var parsedUrl = parseHfdmUrl(url, ViewingService, api, viewingServiceBaseUrl);
    if (parsedUrl) {
      var hfdm;
      try {
        if (!(hfdm = getHFDM())) {
          onFailure(500, "HFDM not initialized", { url: url });
          return;
        }

        // Figure out what we want and ask for it.
        switch (parsedUrl.type) {
          case "manifest":
            onFailure('Must not be called from here...');
            break;
          case "thumbnails":
            hfdm.getThumbnailId(parsedUrl, function (id) {
              getDataContent(viewingServiceBaseUrl, parsedUrl, id, onSuccess, onFailure, options);
            }, onFailure, options);
            break;
          default:
            hfdm.getValueAtPath(parsedUrl.branch, parsedUrl.path.concat("objectKey"), function (id) {
              getDataContent(viewingServiceBaseUrl, parsedUrl, id, onSuccess, onFailure, options);
            }, onFailure, options);
            break;}

      } catch (e) {
        // Exception - return non specific error.
        onFailure(0, "Exception", { url: url, exception: e });
      }
    } else {
      // Not HFDM use original viewing service
      _rawGet.call(ViewingService, viewingServiceBaseUrl, api, url, onSuccess, onFailure, options);
    }
  };
}