/**
 * Contains viewer setting preference names for 3D models.
 * @typedef {Object} Prefs3D
 * @property {string} VIEW_CUBE - Sets the visibility of the viewcube.
 * @property {string} VIEW_CUBE_COMPASS - Sets the visibility of the viewcube compass. The compass will only be displayed if model contains orientation data.
 * @property {string} ALWAYS_USE_PIVOT - Orbit controls always orbit around the currently set pivot point.
 * @property {string} ZOOM_TOWARDS_PIVOT - Default direction for camera dolly (zoom) operations to be towards the camera pivot point.
 * @property {string} REVERSE_HORIZONTAL_LOOK_DIRECTION - Sets a view navigation option to reverse the default direction for horizontal look operations.
 * @property {string} REVERSE_VERTICAL_LOOK_DIRECTION - Sets a view navigation option to reverse the default direction for vertical look operations.
 * @property {string} ORBIT_PAST_WORLD_POLES - Set a view navigation option to allow the orbit controls to move the camera beyond the north and south poles (world up/down direction).
 * @property {string} CLICK_TO_SET_COI - Modify the default click behavior for the viewer.
 * @property {string} GHOSTING - Toggles ghosting during search and isolate.
 * @property {string} OPTIMIZE_NAVIGATION - Toggles whether the navigation should be optimized for performance.
 * @property {string} AMBIENT_SHADOWS - Enables or disables ambient shadows.
 * @property {string} ANTIALIASING - Enables or disables antialiasing.
 * @property {string} GROUND_SHADOW - Toggles ground shadow.
 * @property {string} GROUND_REFLECTION - Toggles ground reflection.
 * @property {string} LINE_RENDERING - Hides all lines in the scene.
 * @property {string} EDGE_RENDERING - Turns edge topology display on/off (where available).
 * @property {string} LIGHT_PRESET - Sets the Light Presets (Environments) for the Viewer.
 * @property {string} ENV_MAP_BACKGROUND - Toggles environment map for background.
 * @property {string} FIRST_PERSON_TOOL_POPUP - Toggles first person tool popup.
 * @property {string} BIM_WALK_TOOL_POPUP - Toggles the bimwalk tool popup.
 * @property {string} BIM_WALK_NAVIGATOR_TYPE - Identifier for the bimWalkNavigatorType preference. This is used to set the BimWalk tool navigator.
 * @property {string} DEFAULT_NAVIGATION_TOOL_3D - identifier for the toolToUse preference. This is used to set which navigation tool will be used.
 * @property {string} SELECTION_MODE - identifier for the selectionMode preference. This is used to set which selection mode (Leaf, First, Last object) wil be used by the viewer.
 * @property {string} ENABLE_CUSTOM_ORBIT_TOOL_CURSOR - identifier for whether the OrbitDollyPanTool will customize the cursor visuals.
 */

/**
     * Preference names that can be used to set {@link Autodesk.Viewing.Private.Preferences}
     * These preference names only apply for 3D models. 
     * @type {Prefs3D}
     * @alias Autodesk.Viewing.Private.Prefs3D
     */
export var Prefs3D = {
  VIEW_CUBE: 'viewCube',
  VIEW_CUBE_COMPASS: 'viewCubeCompass',
  ALWAYS_USE_PIVOT: 'alwaysUsePivot',
  ZOOM_TOWARDS_PIVOT: 'zoomTowardsPivot',
  REVERSE_HORIZONTAL_LOOK_DIRECTION: 'reverseHorizontalLookDirection',
  REVERSE_VERTICAL_LOOK_DIRECTION: 'reverseVerticalLookDirection',
  ORBIT_PAST_WORLD_POLES: 'orbitPastWorldPoles',
  CLICK_TO_SET_COI: 'clickToSetCOI',
  GHOSTING: 'ghosting',
  OPTIMIZE_NAVIGATION: 'optimizeNavigation',
  AMBIENT_SHADOWS: 'ambientShadows',
  ANTIALIASING: 'antialiasing',
  GROUND_SHADOW: 'groundShadow',
  GROUND_REFLECTION: 'groundReflection',
  LINE_RENDERING: 'lineRendering',
  EDGE_RENDERING: 'edgeRendering',
  LIGHT_PRESET: 'lightPreset',
  ENV_MAP_BACKGROUND: 'envMapBackground',
  FIRST_PERSON_TOOL_POPUP: 'firstPersonToolPopup',
  BIM_WALK_TOOL_POPUP: 'bimWalkToolPopup',
  BIM_WALK_NAVIGATOR_TYPE: 'bimWalkNavigatorType',
  DEFAULT_NAVIGATION_TOOL_3D: 'defaultNavigationTool3D',
  SELECTION_MODE: 'selectionMode',
  ENABLE_CUSTOM_ORBIT_TOOL_CURSOR: 'enableCustomOrbitToolCursor' };


/**
                                                                     * Contains viewer setting preference names for 2D models.
                                                                     * @typedef {Object} Prefs2D
                                                                     * @property {string} GRAYSCALE - Overrides line colors in 2D models to render in shades of gray.
                                                                     * @property {string} SWAP_BLACK_AND_WHITE - Will switch to white lines on a black background.
                                                                     */

/**
                                                                         * Preference names that can be used to set {@link Autodesk.Viewing.Private.Preferences}
                                                                         * These preference names only apply for 2D models. 
                                                                         * @type {Prefs2D}
                                                                         * @alias Autodesk.Viewing.Private.Prefs2D
                                                                         */
export var Prefs2D = {
  GRAYSCALE: 'grayscale',
  SWAP_BLACK_AND_WHITE: 'swapBlackAndWhite' };


/**
                                                * Contains viewer setting preference names that are available to both 3D and 2D models.
                                                * @typedef {Object} Prefs
                                                * @property {string} PROGRESSIVE_RENDERING - Toggles whether progressive rendering is used.
                                                * @property {string} OPEN_PROPERTIES_ON_SELECT - Open property panel when selecting an object. (Only for GuiViewer3D)
                                                * @property {string} POINT_RENDERING - Hides all points in the scene.
                                                * @property {string} BACKGROUND_COLOR_PRESET - Sets a color to the background.
                                                * @property {string} REVERSE_MOUSE_ZOOM_DIR - Reverse the default direction for camera dolly (zoom) operations.
                                                * @property {string} LEFT_HANDED_MOUSE_SETUP - Reverse mouse buttons from their default assignment (i.e. Left mouse operation becomes right mouse and vice versa).
                                                * @property {string} FUSION_ORBIT - Sets the orbit to fusion orbit.
                                                * @property {string} FUSION_ORBIT_CONSTRAINED - Sets the the orbit to the contrained fusion orbit.
                                                * @property {string} WHEEL_SETS_PIVOT - Sets wheel-zoom action to automatically reset the orbit pivot to the location under the cursor.
                                                */

/**
                                                    * Preference names that can be used to set {@link Autodesk.Viewing.Private.Preferences}
                                                    * These preference names are shared between both 3D and 2D models. 
                                                    * @type {Prefs}
                                                    * @alias Autodesk.Viewing.Private.Prefs
                                                    */
export var Prefs = {
  PROGRESSIVE_RENDERING: 'progressiveRendering',
  OPEN_PROPERTIES_ON_SELECT: 'openPropertiesOnSelect',
  POINT_RENDERING: 'pointRendering',
  BACKGROUND_COLOR_PRESET: 'backgroundColorPreset',
  REVERSE_MOUSE_ZOOM_DIR: 'reverseMouseZoomDir',
  LEFT_HANDED_MOUSE_SETUP: 'leftHandedMouseSetup',
  FUSION_ORBIT: 'fusionOrbit',
  FUSION_ORBIT_CONSTRAINED: 'fusionOrbitConstrained',
  WHEEL_SETS_PIVOT: 'wheelSetsPivot' };