
import { SceneMath } from "../wgs/scene/SceneMath";

/**
                                                     * Used in order to convert from Leaflet coordinates to PDF coordinates.
                                                     * 
                                                     * Assumes the current model is loaded with PDFLoader.
                                                     */
function leafletToPdfWorld(viewer, point) {
  var leafletNormalizingMatrix = getLeafletNormalizingMatrix(viewer);
  point.applyMatrix4(leafletNormalizingMatrix);

  var pdfNormalizingMatrix = SceneMath.getNormalizingMatrix(viewer.model);
  pdfNormalizingMatrix = pdfNormalizingMatrix.getInverse(pdfNormalizingMatrix);
  point.applyMatrix4(pdfNormalizingMatrix);

  return point;
};

/**
    * Used in order to convert from PDF coordinates to Leaflet coordinates.
    * 
    * Assumes the current model is loaded with PDFLoader.
    */
function pdfToLeafletWorld(viewer, point) {
  var pdfNormalizingMatrix = SceneMath.getNormalizingMatrix(viewer.model);
  point.applyMatrix4(pdfNormalizingMatrix);

  var leafletNormalizingMatrix = getLeafletNormalizingMatrix(viewer);
  leafletNormalizingMatrix = leafletNormalizingMatrix.getInverse(leafletNormalizingMatrix);
  point.applyMatrix4(leafletNormalizingMatrix);

  return point;
};

/**
    * Searches inside the bubble for the Leaflets params.
    */
function getLeafletLoadOptions(viewer) {
  var documentNode = viewer.impl.model.getDocumentNode();
  var leafletItem = documentNode.search(Autodesk.Viewing.BubbleNode.LEAFLET_NODE)[0]._raw();

  var options = {};
  var _document = new Autodesk.Viewing.Document(documentNode.getRootNode()._raw(), '');
  _document.getLeafletParams(options, documentNode, leafletItem);

  return options;
};

/**
    * Calculates the Leaflet's bounding box, using parameters from the bubble.
    * Use the same original logic from the leaflet loader.
    */
function getLeafletBoundingBox(viewer) {
  var texQuadConfig = new Autodesk.Viewing.Private.TexQuadConfig();
  var options = getLeafletLoadOptions(viewer);
  texQuadConfig.initFromLoadOptions(null, options);
  var boundingBox = texQuadConfig.getBBox();

  return boundingBox;
};

function getLeafletNormalizingMatrix(viewer) {
  var bbox = getLeafletBoundingBox(viewer);
  var matrix = SceneMath.getNormalizingMatrix(null, bbox);

  return matrix;
};


export var PDFUtils = {
  leafletToPdfWorld: leafletToPdfWorld,
  pdfToLeafletWorld: pdfToLeafletWorld,
  getLeafletLoadOptions: getLeafletLoadOptions,
  getLeafletBoundingBox: getLeafletBoundingBox,
  getLeafletNormalizingMatrix: getLeafletNormalizingMatrix };