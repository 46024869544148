import { getResourceUrl, getScript } from './globals';
import { getGlobal } from './compat';

var _window = getGlobal();
var _document = _window.document;

// Keeps track of which <scripts> are downloading. 
//Key is "script.src" (in all lowercase) and value is an array of callbacks.
var _downloading = {};


/**
                        * @param {string} libNamespace - window property name expected to be loaded after library is downloaded. Can be undefined, too.
                        * @param {string} libName - url to load the library from.
                        * @param {function} callback - success callback function
                        * @param {function} [onError] - error callback function
                        * @param {string} amdName - Should be the name module defined on the define function.
                        * @description  Loads a script (e.g. an external library JS) and calls the callback once loaded. Used for delayed loading of required libraries. Accepts both relative and absolute URLs.
                        * 
                        * @private
                        */
export function loadDependency(libNamespace, libName, callback, onError, amdName) {
  if (libNamespace === undefined || typeof _window[libNamespace] === "undefined") {

    var scriptFileName = libName.indexOf('://') > 0 ? libName : getResourceUrl(libName);

    // Return is the file has already been included.
    if (getScript(scriptFileName)) {
      if (isDownloading(scriptFileName)) {
        notifyOnDownloaded(scriptFileName, [callback, onError]);
      } else {
        // Already downloaded and parsed by runtime.
        callback();
      }
      return;
    }

    var s = _document.createElement("SCRIPT");
    s.src = scriptFileName;
    setDownloading(scriptFileName);

    var clearCallbacks = function clearCallbacks() {
      s.onerror = null;
      s.onload = null;
    };
    var errCallback = function errCallback() {
      clearCallbacks();
      setDownloadComplete(scriptFileName, false);
      onError && onError();
    };
    var successCallback = function successCallback() {
      clearCallbacks();
      setDownloadComplete(scriptFileName, true);
      callback && callback();
    };
    s.onload = successCallback;
    s.onerror = errCallback;
    _document.head.appendChild(s);
  } else
  if (callback)
  callback();
}

/**
   * Supports loadDependency
   *
   * @param {string} scriptFileName - Script being downloaded
   * @returns {boolean} true if the script is already being downloaded but it has not finished yet.
   * @private
   */
function isDownloading(scriptFileName) {
  var key = scriptFileName.toLowerCase();
  for (var fileKey in _downloading) {
    if (fileKey === key)
    return true;
  }
  return false;
}

/**
   * Supports loadDependency
   *
   * @param {string} scriptFileName - Script being downloaded
   * @private
   */
function setDownloading(scriptFileName) {
  var key = scriptFileName.toLowerCase();
  _downloading[key] = [];
}

/**
   * Supports loadDependency
   *
   * @param {string} scriptFileName - Script being downloaded
   * @param {Array} callbacksArray - Array with 2 elements: the success callback (index 0) and the error callback (index 1)
   */
function notifyOnDownloaded(scriptFileName, callbacksArray) {
  var key = scriptFileName.toLowerCase();
  _downloading[key].push(callbacksArray);
}

/**
   * Supports loadDependency.
   * Notifies whoever is waiting for the script to be downlaoded.
   *
   * @param {string} scriptFileName - Script being downloaded
   * @param {boolean} success - whether the download was successful or not
   * @private
   */
function setDownloadComplete(scriptFileName, success) {
  var key = scriptFileName.toLowerCase();
  var notifyList = _downloading[key];
  delete _downloading[key];
  for (var i = 0, len = notifyList.length; i < len; ++i) {
    var callbackArray = notifyList[i];
    if (success) {
      callbackArray[0]();
    } else {
      callbackArray[1]();
    }
  }
}