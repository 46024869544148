import { createPrismMaterial } from './PrismShader';
import { MSDFShape, MSDFContour, MSDFLinearSegment, MSDF_EDGE_COLOR_WHITE } from './MSDF';
import * as THREE from "three";

// Helper functions to parse ugly Protein JSON
function parseMaterialColor(props, name, unused) {
  if (!props || !props["colors"])
  return new THREE.Color(1, 0, 0); //error -- return red

  var cobj = props["colors"][name];
  if (!cobj)
  return new THREE.Color(0, 0, 0); //ok -- color is not defined
  //which in the C++ LMVTK is equal to DEFAULT_COLOR, which is black

  var vals = cobj["values"];
  if (!vals || !vals.length)
  return new THREE.Color(1, 0, 0); //error

  var rgb = vals[0];
  return new THREE.Color(rgb["r"], rgb["g"], rgb["b"]);
}

function parseMaterialScalar(props, name, undefVal) {
  if (!props || !props["scalars"])
  return undefVal;

  var vobj = props["scalars"][name];
  if (!vobj)
  return undefVal;

  return vobj["values"][0];
}

function parseMaterialBoolean(props, name, undefVal) {
  if (!props || !props["booleans"])
  return undefVal;

  var b = props["booleans"][name];
  return b === undefined ? undefVal : b;
}

function parseMaterialGeneric(props, category, name, undefVal) {
  if (!props || !props[category])
  return undefVal;

  var vobj = props[category][name];
  if (!vobj)
  return undefVal;

  return vobj["values"][0];
}

function parseWoodProfile(props, category, name) {
  //Init a default object.
  var ret = {
    bands: 0,
    weights: new THREE.Vector4(1, 1, 1, 1),
    frequencies: new THREE.Vector4(1, 1, 1, 1) };


  if (!props || !props[category])
  return ret;

  var vobj = props[category][name];
  if (!vobj || !vobj.values || !(vobj.values instanceof Array))
  return ret;

  var values = vobj.values;
  ret.bands = values.length / 2;
  for (var i = 0; i < ret.bands; ++i) {
    // Note that the frequencies stored in the material are actually used in the shader as 1/frequency.
    // We perform this computation once here and store these reciprocals, for efficiency.
    ret.frequencies.setComponent(i, 1 / values[2 * i]);
    ret.weights.setComponent(i, values[2 * i + 1]);
  }

  return ret;
}

function parseMaterialScalarWithSceneUnit(props, name, sceneUnit, undefVal) {
  if (!props || !props["scalars"])
  return undefVal;

  var vobj = props["scalars"][name];
  if (!vobj)
  return undefVal;

  return ConvertDistance(vobj["values"][0], vobj["units"], sceneUnit);
}

function parseMaterialGenericConnection(props, category, name, undefVal) {
  if (!props || !props[category])
  return undefVal;

  var vobj = props[category][name];
  if (!vobj)
  return undefVal;

  var connections = vobj["connections"];
  if (!connections)
  return undefVal;

  return vobj["connections"][0];
}

function SRGBToLinearFloat(component) {
  var result = component;

  if (result <= 0.04045)
  result /= 12.92;else

  result = Math.pow((result + 0.055) / 1.055, 2.4);

  return result;
}

function SRGBToLinear(color) {
  var r, g, b;

  r = SRGBToLinearFloat(color.r);
  g = SRGBToLinearFloat(color.g);
  b = SRGBToLinearFloat(color.b);

  return new THREE.Color(r, g, b);
}

// TODO, since web doesn't use AdCoreUnits dependencies, only 9 units are supported in web now.
var UnitPerMeter = {
  MilliMeter: 1000, mm: 1000, 8206: 1000,
  DeciMeter: 10, dm: 10, 8204: 10,
  CentiMeter: 100, cm: 100, 8205: 100,
  Meter: 1, m: 1, 8193: 1,
  KiloMeter: 0.001, km: 0.001, 8201: 0.001,
  Inch: 39.37008, "in": 39.37008, 8214: 39.37008,
  Foot: 3.28084, ft: 3.28084, 8215: 3.28084,
  Mile: 0.00062137, mi: 0.00062137, 8225: 0.00062137,
  Yard: 1.09361, yard: 1.09361, 8221: 1.09361 };


// Convert meter to the new unit.
function ConvertDistance(distance, currentUnit, newUnit) {

  var factor = UnitPerMeter[newUnit];
  if (!factor) {
    factor = 1;
    THREE.warn('Unsupported unit: ' + newUnit);
  }

  var divisor = UnitPerMeter[currentUnit];
  if (!divisor) {
    divisor = 1;
    THREE.warn('Unsupported unit: ' + currentUnit);
  }

  return distance * factor / divisor;
}

function GetBumpScale(props, type, sceneUnit) {
  if (type === 0) {
    var depth = parseMaterialScalarWithSceneUnit(props, "bumpmap_Depth", sceneUnit, 0);

    var scale_x = 1;
    var scale_y = 1;
    if (parseMaterialGeneric(props, "scalars", "texture_RealWorldScale") != null) {
      scale_x = scale_y = parseMaterialScalarWithSceneUnit(props, "texture_RealWorldScale", sceneUnit, 1);
    } else
    {
      scale_x = parseMaterialScalarWithSceneUnit(props, "texture_RealWorldScaleX", sceneUnit, 1);
      scale_y = parseMaterialScalarWithSceneUnit(props, "texture_RealWorldScaleY", sceneUnit, 1);
    }
    scale_x = scale_x === 0 ? 1 : 1 / scale_x;
    scale_y = scale_y === 0 ? 1 : 1 / scale_y;

    return new THREE.Vector2(scale_x * depth, scale_y * depth);
  } else
  {
    var normalScale = parseMaterialGeneric(props, "scalars", "bumpmap_NormalScale", 1);
    return new THREE.Vector2(normalScale, normalScale);
  }
}

function Get2DPrismMapTransform(props, sceneUnit) {

  var worldOffsetX = parseMaterialScalarWithSceneUnit(props, "texture_RealWorldOffsetX", sceneUnit, 0);
  var worldOffsetY = parseMaterialScalarWithSceneUnit(props, "texture_RealWorldOffsetY", sceneUnit, 0);

  var texOffsetU = parseMaterialGeneric(props, "scalars", "texture_UOffset", 0);
  var texOffsetV = parseMaterialGeneric(props, "scalars", "texture_VOffset", 0);

  // Get the real-world size, i.e. the size of the map in a real unit, and use the reciprocal as
  // the scale.  If the scale is zero, use one instead.
  var worldScaleX = 1;
  var worldScaleY = 1;
  if (parseMaterialGeneric(props, "scalars", "texture_RealWorldScale") != null) {
    worldScaleX = worldScaleY = parseMaterialScalarWithSceneUnit(props, "texture_RealWorldScale", sceneUnit, 1);
  } else
  {
    worldScaleX = parseMaterialScalarWithSceneUnit(props, "texture_RealWorldScaleX", sceneUnit, 1);
    worldScaleY = parseMaterialScalarWithSceneUnit(props, "texture_RealWorldScaleY", sceneUnit, 1);
  }
  worldScaleX = worldScaleX === 0 ? 1 : worldScaleX;
  worldScaleY = worldScaleY === 0 ? 1 : worldScaleY;

  // include the additional U and V scales
  var texScaleU = parseMaterialGeneric(props, "scalars", "texture_UScale", 1);
  var texScaleV = parseMaterialGeneric(props, "scalars", "texture_VScale", 1);

  // Get the rotation angle and convert it from degrees to radians.
  var angle = parseMaterialGeneric(props, "scalars", "texture_WAngle", 1);
  angle *= Math.PI / 180.0;

  // Compute the final 3x3 matrix by combining the following transformations:
  // 1. inverse of the real world offset
  // 2. inverse of the real world scale
  // 3. uv scale
  // 4. uv rotation
  // 5. uv offset
  var c = Math.cos(angle),s = Math.sin(angle);
  var cx = texScaleU / worldScaleX,cy = texScaleV / worldScaleY;
  var matrix = {
    elements: [
    c * cx, s * cx, 0,
    -s * cy, c * cy, 0,
    -c * cx * worldOffsetX + s * cy * worldOffsetY + texOffsetU, -s * cx * worldOffsetX - c * cy * worldOffsetY + texOffsetV, 1] };



  return matrix;
}

var PrismImportantSamplingTexture;
function InitPrismImportantSamplingTextures() {
  //random number texture for prism important sampling.
  //We can reuse 3d wood noise texture, but to align with Fusion,
  //use the same random number texture.
  var randomNum = [
  0, 128, 64, 191, 32, 160, 96, 223,
  16, 143, 80, 207, 48, 175, 112, 239,
  8, 135, 72, 199, 40, 167, 103, 231,
  25, 151, 88, 215, 56, 183, 120, 250];


  var randomNumBuffer = new Uint8Array(randomNum);
  var randomNumTex = new THREE.DataTexture(randomNumBuffer, 32, 1,
  THREE.LuminanceFormat,
  THREE.UnsignedByteType,
  THREE.UVMapping,
  THREE.RepeatWrapping, THREE.RepeatWrapping,
  THREE.NearestFilter, THREE.NearestFilter, 0);
  randomNumTex.generateMipmaps = false;
  randomNumTex.flipY = false;
  randomNumTex.needsUpdate = true;

  var areaElement = function areaElement(x, y) {
    return Math.atan2(x * y, Math.sqrt(x * x + y * y + 1.0));
  };

  //Calculate the solid angle, so we don't need to do this in the shader.
  /// http://www.mpia-hd.mpg.de/~mathar/public/mathar20051002.pdf
  /// http://www.rorydriscoll.com/2012/01/15/cubemap-texel-solid-angle/
  var solidAngleBuffer = new Uint8Array(128 * 128);
  var u, v;
  var invFaceSize = 1.0 / 128.0;
  for (var i = 0; i < 128; ++i) {
    for (var j = 0; j < 128; ++j) {
      u = i / 128.0 * 2.0 - 1.0;
      v = j / 128.0 * 2.0 - 1.0;
      u = Math.min(Math.max(-1.0 + invFaceSize, u), 1.0 - invFaceSize);
      v = Math.min(Math.max(-1.0 + invFaceSize, v), 1.0 - invFaceSize);

      var x0 = u - invFaceSize;
      var x1 = u + invFaceSize;
      var y0 = v - invFaceSize;
      var y1 = v + invFaceSize;

      // Compute solid angle of texel area.
      var solidAngle = areaElement(x1, y1) -
      areaElement(x0, y1) -
      areaElement(x1, y0) +
      areaElement(x0, y0);
      //The max result is 0.000244125724. Map to [0, 255]
      solidAngleBuffer[i * 128 + j] = solidAngle * 1000000;
    }
  }

  var solidAngleTex = new THREE.DataTexture(solidAngleBuffer, 128, 128,
  THREE.LuminanceFormat,
  THREE.UnsignedByteType,
  THREE.UVMapping,
  THREE.RepeatWrapping, THREE.RepeatWrapping,
  THREE.NearestFilter, THREE.NearestFilter, 0);
  solidAngleTex.generateMipmaps = false;
  solidAngleTex.flipY = false;
  solidAngleTex.needsUpdate = true;

  PrismImportantSamplingTexture = {
    randomNum: randomNumTex,
    solidAngle: solidAngleTex };

}

var PrismWoodTexture;
//Init the prism wood textures. They are used in all prism 3d wood materials, so keep them
//in the material manager.
function InitPrism3DWoodTextures() {
  var permutation = [
  151, 160, 137, 91, 90, 15, 131, 13, 201, 95, 96, 53, 194, 233, 7, 225,
  140, 36, 103, 30, 69, 142, 8, 99, 37, 240, 21, 10, 23, 190, 6, 148,
  247, 120, 234, 75, 0, 26, 197, 62, 94, 252, 219, 203, 117, 35, 11, 32,
  57, 177, 33, 88, 237, 149, 56, 87, 174, 20, 125, 136, 171, 168, 68, 175,
  74, 165, 71, 134, 139, 48, 27, 166, 77, 146, 158, 231, 83, 111, 229, 122,
  60, 211, 133, 230, 220, 105, 92, 41, 55, 46, 245, 40, 244, 102, 143, 54,
  65, 25, 63, 161, 1, 216, 80, 73, 209, 76, 132, 187, 208, 89, 18, 169,
  200, 196, 135, 130, 116, 188, 159, 86, 164, 100, 109, 198, 173, 186, 3, 64,
  52, 217, 226, 250, 124, 123, 5, 202, 38, 147, 118, 126, 255, 82, 85, 212,
  207, 206, 59, 227, 47, 16, 58, 17, 182, 189, 28, 42, 223, 183, 170, 213,
  119, 248, 152, 2, 44, 154, 163, 70, 221, 153, 101, 155, 167, 43, 172, 9,
  129, 22, 39, 253, 19, 98, 108, 110, 79, 113, 224, 232, 178, 185, 112, 104,
  218, 246, 97, 228, 251, 34, 242, 193, 238, 210, 144, 12, 191, 179, 162, 241,
  81, 51, 145, 235, 249, 14, 239, 107, 49, 192, 214, 31, 181, 199, 106, 157,
  184, 84, 204, 176, 115, 121, 50, 45, 127, 4, 150, 254, 138, 236, 205, 93,
  222, 114, 67, 29, 24, 72, 243, 141, 128, 195, 78, 66, 215, 61, 156, 180];

  var permutationBuffer = new Uint8Array(permutation);
  var permutationTex = new THREE.DataTexture(permutationBuffer, 256, 1,
  THREE.LuminanceFormat,
  THREE.UnsignedByteType,
  THREE.UVMapping,
  THREE.RepeatWrapping, THREE.RepeatWrapping,
  THREE.NearestFilter, THREE.NearestFilter, 0);
  permutationTex.generateMipmaps = false;
  permutationTex.flipY = false;
  permutationTex.needsUpdate = true;
  //This is different with OGS desktop. OGS uses a float texture. I map these number to
  //unsight byte, since some platform may not support float texture. Test result shows that
  //the pixel diffrence is very small.
  var gradientData = [
  225, 39, 122, 231, 29, 173, 15, 159, 75, 88, 233, 19, 179, 79, 72, 94,
  54, 73, 151, 161, 171, 113, 221, 144, 127, 83, 168, 19, 88, 122, 62, 225,
  109, 128, 246, 247, 172, 101, 61, 139, 211, 168, 64, 210, 224, 82, 87, 97,
  119, 250, 201, 44, 242, 239, 154, 99, 126, 13, 44, 70, 246, 170, 100, 52,
  135, 28, 187, 22, 207, 119, 199, 1, 235, 187, 55, 131, 190, 124, 222, 249,
  236, 53, 225, 231, 71, 30, 173, 185, 153, 47, 79, 133, 225, 10, 140, 62,
  17, 99, 100, 29, 137, 95, 142, 244, 76, 5, 83, 124, 38, 216, 253, 195,
  44, 210, 148, 185, 188, 39, 78, 195, 132, 30, 60, 73, 92, 223, 133, 80,
  230, 56, 118, 207, 79, 15, 251, 211, 111, 21, 79, 23, 240, 146, 150, 207,
  3, 61, 103, 27, 148, 6, 31, 127, 235, 58, 173, 244, 116, 81, 34, 120,
  192, 213, 188, 226, 97, 23, 16, 161, 106, 80, 242, 148, 35, 37, 91, 117,
  51, 216, 97, 193, 126, 222, 39, 38, 133, 217, 215, 23, 237, 57, 205, 42,
  222, 165, 126, 133, 33, 8, 227, 154, 27, 18, 56, 11, 192, 120, 80, 92,
  236, 38, 210, 207, 128, 31, 135, 39, 123, 5, 49, 127, 107, 200, 34, 14,
  153, 239, 134, 19, 248, 162, 58, 201, 159, 198, 243, 158, 72, 5, 138, 184,
  222, 200, 34, 141, 233, 40, 195, 238, 191, 122, 171, 32, 66, 254, 229, 197];

  var gradientBuffer = new Uint8Array(gradientData);
  var gradientTex = new THREE.DataTexture(gradientBuffer, 256, 1,
  THREE.LuminanceFormat,
  THREE.UnsignedByteType,
  THREE.UVMapping,
  THREE.RepeatWrapping, THREE.RepeatWrapping,
  THREE.NearestFilter, THREE.NearestFilter, 0);

  gradientTex.generateMipmaps = false;
  gradientTex.flipY = false;
  gradientTex.needsUpdate = true;

  var perm = function perm(x) {
    return permutation[x % 256];
  };

  var perm2D = new Array(256 * 256 * 4);
  var A, AA, AB, B, BA, BB, index, x;
  for (var y = 0; y < 256; ++y) {
    for (x = 0; x < 256; ++x) {
      A = perm(x) + y;
      AA = perm(A);
      AB = perm(A + 1);
      B = perm(x + 1) + y;
      BA = perm(B);
      BB = perm(B + 1);

      // Store (AA, AB, BA, BB) in pixel (x,y)
      index = 4 * (y * 256 + x);
      perm2D[index] = AA;
      perm2D[index + 1] = AB;
      perm2D[index + 2] = BA;
      perm2D[index + 3] = BB;
    }}
  var perm2DBuffer = new Uint8Array(perm2D);
  var perm2DTex = new THREE.DataTexture(perm2DBuffer, 256, 256,
  THREE.RGBAFormat,
  THREE.UnsignedByteType,
  THREE.UVMapping,
  THREE.RepeatWrapping, THREE.RepeatWrapping,
  THREE.NearestFilter, THREE.NearestFilter, 0);
  perm2DTex.generateMipmaps = false;
  perm2DTex.flipY = false;
  perm2DTex.needsUpdate = true;

  var gradients3D = [
  1, 1, 0, -1, 1, 0, 1, -1, 0, -1, -1, 0,
  1, 0, 1, -1, 0, 1, 1, 0, -1, -1, 0, -1,
  0, 1, 1, 0, -1, 1, 0, 1, -1, 0, -1, -1,
  1, 1, 0, 0, -1, 1, -1, 1, 0, 0, -1, -1];

  var permGrad = new Array(1024);
  for (x = 0; x < 256; ++x) {
    var i = permutation[x] % 16;
    // Convert the gradient to signed-normalized int.
    permGrad[x * 4] = gradients3D[i * 3] * 127 + 128;
    permGrad[x * 4 + 1] = gradients3D[i * 3 + 1] * 127 + 128;
    permGrad[x * 4 + 2] = gradients3D[i * 3 + 2] * 127 + 128;
    permGrad[x * 4 + 3] = 0;
  }
  var permGradBuffer = new Uint8Array(permGrad);
  var permGradTex = new THREE.DataTexture(permGradBuffer, 256, 1,
  THREE.RGBAFormat,
  THREE.UnsignedByteType,
  THREE.UVMapping,
  THREE.RepeatWrapping, THREE.RepeatWrapping,
  THREE.NearestFilter, THREE.NearestFilter, 0);
  permGradTex.generateMipmaps = false;
  permGradTex.flipY = false;
  permGradTex.needsUpdate = true;

  PrismWoodTexture = {
    permutation: permutationTex,
    gradient: gradientTex,
    perm2D: perm2DTex,
    permGrad: permGradTex };

}

function swapPrismWoodTextures(newTex) {
  var oldTex = PrismWoodTexture;
  PrismWoodTexture = newTex;
  return oldTex;
}

function disposePrismWoodTextures(textures) {
  if (textures) {
    textures.permutation.dispose();
    textures.gradient.dispose();
    textures.perm2D.dispose();
    textures.permGrad.dispose();
  }
}

function parseWoodMap(tm, props, name) {
  tm[name + "_enable"] = parseMaterialGeneric(props, "booleans", name + "_enable", 0);
  var prof = parseWoodProfile(props, "scalars", name + "_prof");
  tm[name + "_bands"] = prof.bands;
  tm[name + "_weights"] = prof.weights;
  tm[name + "_frequencies"] = prof.frequencies;
}



function convertMaterial(matObj, sceneUnit, tm, index) {

  index = index || matObj["userassets"];
  var innerMats = matObj["materials"];
  var innerMat = innerMats[index];
  if (innerMat) {
    var definition = innerMat['definition'];
    // if this is a tiling, need to get the real grout material
    if (definition === 'TilingPattern') {
      // if first "material" is a tiling pattern, look at the grout material, which must always exist.
      var idx = innerMat.properties.references.grout_material.connections[0];
      innerMat = innerMats[idx];
    }
  }

  var props = innerMat["properties"];

  var isPrism = isPrismMaterial(matObj);

  if (!tm) {
    tm = isPrism ? createPrismMaterial() : new THREE.MeshPhongMaterial();
  } else if (isPrism ? !tm.isPrismMaterial : !(tm instanceof THREE.MeshPhongMaterial)) {
    return null;
  } else {
    tm.needsUpdate = true;
  }
  var map, texProps;
  tm.proteinMat = matObj;
  tm.proteinCategories = innerMat.categories;
  tm.packedNormals = true;

  if (innerMat && isPrism) {
    tm.tag = innerMat["tag"];
    tm.prismType = innerMat["definition"];
    if (tm.prismType === undefined)
    tm.prismType = "";

    // check for the new IsSingleSided tag from ATF. Note that we assume all objects are
    // single-sided (tm.side == THREE.FrontSide) unless tagged otherwise.
    if (matObj.IsSingleSided !== undefined && matObj.IsSingleSided === false)
    tm.side = THREE.DoubleSide;
    // else, by default, tm.side is FrontSide

    var mapList = tm.mapList;

    tm.transparent = false;
    tm.envExponentMin = 1.0;
    tm.envExponentMax = 512.0;
    tm.envExponentCount = 10.0;

    // among other things, set up mapList and note what map, if any, is attached to each property such as "surface_albedo".
    tm.surface_albedo = SRGBToLinear(parseMaterialColor(props, "surface_albedo", new THREE.Color(1, 0, 0)));
    mapList.surface_albedo_map = parseMaterialGenericConnection(props, "colors", "surface_albedo", null);

    tm.surface_anisotropy = parseMaterialGeneric(props, "scalars", "surface_anisotropy", 0);
    mapList.surface_anisotropy_map = parseMaterialGenericConnection(props, "scalars", "surface_anisotropy", null);

    tm.surface_rotation = parseMaterialGeneric(props, "scalars", "surface_rotation", 0);
    mapList.surface_rotation_map = parseMaterialGenericConnection(props, "scalars", "surface_rotation", null);

    tm.surface_roughness = parseMaterialGeneric(props, "scalars", "surface_roughness", 0);
    mapList.surface_roughness_map = parseMaterialGenericConnection(props, "scalars", "surface_roughness", null);

    mapList.surface_cutout_map = parseMaterialGenericConnection(props, "textures", "surface_cutout", null);
    mapList.surface_normal_map = parseMaterialGenericConnection(props, "textures", "surface_normal", null);

    // if there is a cutout map, we must make the surface double-sided since we can see through to the inside
    if (mapList.surface_cutout_map != null) {
      tm.side = THREE.DoubleSide;
      tm.transparent = true;
    }

    switch (tm.prismType) {
      case 'PrismOpaque':
        tm.opaque_albedo = SRGBToLinear(parseMaterialColor(props, "opaque_albedo", new THREE.Color(1, 0, 0)));
        mapList.opaque_albedo_map = parseMaterialGenericConnection(props, "colors", "opaque_albedo", null);

        tm.opaque_luminance_modifier = SRGBToLinear(parseMaterialColor(props, "opaque_luminance_modifier", new THREE.Color(0, 0, 0)));
        mapList.opaque_luminance_modifier_map = parseMaterialGenericConnection(props, "colors", "opaque_luminance_modifier", null);

        tm.opaque_f0 = parseMaterialGeneric(props, "scalars", "opaque_f0", 0);
        mapList.opaque_f0_map = parseMaterialGenericConnection(props, "scalars", "opaque_f0", null);

        tm.opaque_luminance = parseMaterialGeneric(props, "scalars", "opaque_luminance", 0);

        break;
      case 'PrismMetal':
        tm.metal_f0 = SRGBToLinear(parseMaterialColor(props, "metal_f0", new THREE.Color(1, 0, 0)));
        mapList.metal_f0_map = parseMaterialGenericConnection(props, "colors", "metal_f0", null);

        break;
      case 'PrismLayered':
        tm.layered_bottom_f0 = SRGBToLinear(parseMaterialColor(props, "layered_bottom_f0", new THREE.Color(1, 1, 1)));
        mapList.layered_bottom_f0_map = parseMaterialGenericConnection(props, "colors", "layered_bottom_f0", null);

        tm.layered_diffuse = SRGBToLinear(parseMaterialColor(props, "layered_diffuse", new THREE.Color(1, 0, 0)));
        mapList.layered_diffuse_map = parseMaterialGenericConnection(props, "colors", "layered_diffuse", null);

        tm.layered_anisotropy = parseMaterialGeneric(props, "scalars", "layered_anisotropy", 0);
        mapList.layered_anisotropy_map = parseMaterialGenericConnection(props, "scalars", "layered_anisotropy", null);

        tm.layered_f0 = parseMaterialGeneric(props, "scalars", "layered_f0", 0);
        mapList.layered_f0_map = parseMaterialGenericConnection(props, "scalars", "layered_f0", null);

        tm.layered_fraction = parseMaterialGeneric(props, "scalars", "layered_fraction", 0);
        mapList.layered_fraction_map = parseMaterialGenericConnection(props, "scalars", "layered_fraction", null);

        tm.layered_rotation = parseMaterialGeneric(props, "scalars", "layered_rotation", 0);
        mapList.layered_rotation_map = parseMaterialGenericConnection(props, "scalars", "layered_rotation", null);

        tm.layered_roughness = parseMaterialGeneric(props, "scalars", "layered_roughness", 0);
        mapList.layered_roughness_map = parseMaterialGenericConnection(props, "scalars", "layered_roughness", null);

        mapList.layered_normal_map = parseMaterialGenericConnection(props, "textures", "layered_normal", null);

        break;
      case 'PrismTransparent':
        tm.transparent_color = SRGBToLinear(parseMaterialColor(props, "transparent_color", new THREE.Color(1, 0, 0)));

        tm.transparent_distance = parseMaterialGeneric(props, "scalars", "transparent_distance", 0);

        tm.transparent_ior = parseMaterialGeneric(props, "scalars", "transparent_ior", 0);

        tm.transparent = true;

        break;

      case 'PrismGlazing':
        tm.glazing_f0 = SRGBToLinear(parseMaterialColor(props, "glazing_f0", new THREE.Color(1, 1, 1)));
        mapList.glazing_f0_map = parseMaterialGenericConnection(props, "colors", "glazing_f0", null);

        tm.glazing_transmission_color = SRGBToLinear(parseMaterialColor(props, "glazing_transmission_color", new THREE.Color(1, 1, 1)));
        mapList.glazing_transmission_color_map = parseMaterialGenericConnection(props, "colors", "glazing_transmission_color", null);

        tm.glazing_transmission_roughness = parseMaterialScalar(props, "glazing_transmission_roughness", 0);
        mapList.glazing_transmission_roughness_map = parseMaterialGenericConnection(props, "scalars", "glazing_transmission_roughness", null);

        tm.side = parseMaterialGeneric(props, "booleans", "glazing_backface_culling", false) ? THREE.FrontSide : THREE.DoubleSide;

        tm.transparent = true;

        break;
      case 'PrismWood':
        parseWoodMap(tm, props, "wood_fiber_cosine");

        parseWoodMap(tm, props, "wood_fiber_perlin");
        tm.wood_fiber_perlin_scale_z = parseMaterialGeneric(props, "scalars", "wood_fiber_perlin_scale_z", 0);

        parseWoodMap(tm, props, "wood_growth_perlin");

        tm.wood_latewood_ratio = parseMaterialGeneric(props, "scalars", "wood_latewood_ratio", 0);
        tm.wood_earlywood_sharpness = parseMaterialGeneric(props, "scalars", "wood_earlywood_sharpness", 0);
        tm.wood_latewood_sharpness = parseMaterialGeneric(props, "scalars", "wood_latewood_sharpness", 0);
        tm.wood_ring_thickness = parseMaterialGeneric(props, "scalars", "wood_ring_thickness", 0);

        parseWoodMap(tm, props, "wood_earlycolor_perlin");
        tm.wood_early_color = SRGBToLinear(parseMaterialColor(props, "wood_early_color", new THREE.Color(1, 0, 0)));

        tm.wood_use_manual_late_color = parseMaterialGeneric(props, "booleans", "wood_use_manual_late_color", 0);
        tm.wood_manual_late_color = SRGBToLinear(parseMaterialColor(props, "wood_manual_late_color", new THREE.Color(1, 0, 0)));

        parseWoodMap(tm, props, "wood_latecolor_perlin");
        tm.wood_late_color_power = parseMaterialGeneric(props, "scalars", "wood_late_color_power", 0);

        parseWoodMap(tm, props, "wood_diffuse_perlin");
        tm.wood_diffuse_perlin_scale_z = parseMaterialGeneric(props, "scalars", "wood_diffuse_perlin_scale_z", 0);

        tm.wood_use_pores = parseMaterialGeneric(props, "booleans", "wood_use_pores", 0);
        tm.wood_pore_type = parseMaterialGeneric(props, "choicelists", "wood_pore_type", 0);
        tm.wood_pore_radius = parseMaterialGeneric(props, "scalars", "wood_pore_radius", 0);
        tm.wood_pore_cell_dim = parseMaterialGeneric(props, "scalars", "wood_pore_cell_dim", 0);
        tm.wood_pore_color_power = parseMaterialGeneric(props, "scalars", "wood_pore_color_power", 0);
        tm.wood_pore_depth = parseMaterialGeneric(props, "scalars", "wood_pore_depth", 0);

        tm.wood_use_rays = parseMaterialGeneric(props, "booleans", "wood_use_rays", 0);
        tm.wood_ray_color_power = parseMaterialGeneric(props, "scalars", "wood_ray_color_power", 0);
        tm.wood_ray_seg_length_z = parseMaterialGeneric(props, "scalars", "wood_ray_seg_length_z", 0);
        tm.wood_ray_num_slices = parseMaterialGeneric(props, "integers", "wood_ray_num_slices", 0);
        tm.wood_ray_ellipse_z2x = parseMaterialGeneric(props, "scalars", "wood_ray_ellipse_z2x", 0);
        tm.wood_ray_ellipse_radius_x = parseMaterialGeneric(props, "scalars", "wood_ray_ellipse_radius_x", 0);

        tm.wood_use_latewood_bump = parseMaterialGeneric(props, "booleans", "wood_use_latewood_bump", 0);
        tm.wood_latewood_bump_depth = parseMaterialGeneric(props, "scalars", "wood_latewood_bump_depth", 0);

        tm.wood_use_groove_roughness = parseMaterialGeneric(props, "booleans", "wood_use_groove_roughness", 0);
        tm.wood_groove_roughness = parseMaterialGeneric(props, "scalars", "wood_groove_roughness", 0);
        tm.wood_diffuse_lobe_weight = parseMaterialGeneric(props, "scalars", "wood_diffuse_lobe_weight", 0);

        tm.wood_curly_distortion_enable = parseMaterialGeneric(props, "booleans", "wood_curly_distortion_enable", 0);
        tm.wood_curly_distortion_scale = parseMaterialGeneric(props, "scalars", "wood_curly_distortion_scale", 0);
        mapList.wood_curly_distortion_map = parseMaterialGenericConnection(props, "scalars", "wood_curly_distortion_map", null);

        //Create the wood noise textures. They are used for all wood materials.
        if (!PrismWoodTexture)
        InitPrism3DWoodTextures();

        tm.uniforms.permutationMap.value = PrismWoodTexture['permutation'];
        tm.uniforms.gradientMap.value = PrismWoodTexture['gradient'];
        tm.uniforms.perm2DMap.value = PrismWoodTexture['perm2D'];
        tm.uniforms.permGradMap.value = PrismWoodTexture['permGrad'];

        break;

      default:
        THREE.warn('Unknown prism type: ' + tm.prismType);}


    if (tm.enableImportantSampling && (tm.surface_anisotropy || tm.surface_rotation || tm.layered_anisotropy || tm.layered_rotation)) {
      if (!PrismImportantSamplingTexture)
      InitPrismImportantSamplingTextures();
      tm.uniforms.importantSamplingRandomMap.value = PrismImportantSamplingTexture.randomNum;
      tm.uniforms.importantSamplingSolidAngleMap.value = PrismImportantSamplingTexture.solidAngle;
    }

    // now that the mapList is set up, populate it
    tm.defines = {};
    tm.textureMaps = {};
    for (var p in mapList) {
      // does the map exist? If not, continue on.
      if (!mapList[p])
      continue;

      // the map exists for this property, so set the various values.
      var textureObj = innerMats[mapList[p]];
      texProps = textureObj["properties"];
      textureObj.matrix = get2DMapTransform(textureObj, true, sceneUnit);

      var uriType = textureObj["definition"] == "BumpMap" ?
      "bumpmap_Bitmap" :
      "unifiedbitmap_Bitmap";

      var uriPointer = texProps["uris"][uriType]["values"];
      var uri = uriPointer[0];
      if (!uri)
      continue;

      map = {
        mapName: p,
        uri: uri,
        uriPointer: uriPointer,
        textureObj: textureObj,
        isPrism: true };

      tm.textureMaps[map.mapName] = map;

      // This array gives the various #defines that are associated with this instance of
      // the PRISM material.
      tm.defines["USE_" + p.toUpperCase()] = "";
    }

    tm.defines[tm.prismType.toUpperCase()] = "";
    if (tm.prismType == 'PrismWood' && tm.enable3DWoodBump)
    tm.defines['PRISMWOODBUMP'] = "";
    if (tm.enableImportantSampling)
    tm.defines['ENABLEIMPORTANTSAMPLING'] = "";

  } else
  if (innerMat && !isPrism && innerMat["definition"] == "SimplePhong") {

    tm.tag = innerMat["tag"];
    tm.proteinType = innerMat["proteinType"];
    if (tm.proteinType === undefined)
    tm.proteinType = null;

    var baked_lighting = parseMaterialBoolean(props, "generic_baked_lighting", false);
    tm.disableEnvMap = baked_lighting;

    var a = tm.ambient = parseMaterialColor(props, "generic_ambient");
    var d = tm.color = parseMaterialColor(props, "generic_diffuse");
    var s = tm.specular = parseMaterialColor(props, "generic_specular");
    var e = tm.emissive = parseMaterialColor(props, "generic_emissive");

    tm.shininess = parseMaterialScalar(props, "generic_glossiness", 30);
    tm.opacity = 1.0 - parseMaterialScalar(props, "generic_transparency", 0);
    tm.reflectivity = parseMaterialScalar(props, "generic_reflectivity_at_0deg", 0);

    var isNormal = parseMaterialBoolean(props, "generic_bump_is_normal");
    var scale = parseMaterialScalar(props, "generic_bump_amount", 0);

    // If cannot read the scale, set the scale to 1 which is the default value for prism and protein.
    if (scale == null)
    scale = 1;

    if (isNormal) {
      if (scale > 1)
      scale = 1;
      tm.normalScale = new THREE.Vector2(scale, scale);
    } else
    {
      if (scale >= 1.0)
      scale = 0.03;
      tm.bumpScale = scale;
    }

    var isMetal = parseMaterialBoolean(props, "generic_is_metal");
    if (isMetal !== undefined)
    tm.metal = isMetal;

    var backfaceCulling = parseMaterialBoolean(props, "generic_backface_cull");
    if (backfaceCulling !== undefined && !backfaceCulling)
    tm.side = THREE.DoubleSide;

    tm.transparent = innerMat["transparent"];

    tm.textureMaps = {};
    var textures = innerMat["textures"];
    for (var texType in textures) {

      map = {};

      map.textureObj = innerMats[textures[texType]["connections"][0]];
      texProps = map.textureObj["properties"];
      map.textureObj.matrix = get2DMapTransform(map.textureObj, false, sceneUnit);

      // Grab URI
      //The uriPointer is used for transforming texture paths in material rewrite workflows
      map.uriPointer = texProps["uris"]["unifiedbitmap_Bitmap"]["values"];
      map.uri = map.uriPointer[0];
      if (!map.uri)
      continue;

      // Figure out map name

      if (texType == "generic_diffuse") {
        map.mapName = "map";

        if (!tm.color || tm.color.r === 0 && tm.color.g === 0 && tm.color.b === 0)
        tm.color.setRGB(1, 1, 1);
      } else
      if (texType == "generic_bump") {
        if (isNormal)
        map.mapName = "normalMap";else

        map.mapName = "bumpMap";
      } else
      if (texType == "generic_specular") {
        map.mapName = "specularMap";
      } else
      if (texType == "generic_alpha") {
        map.mapName = "alphaMap";
        tm.side = THREE.DoubleSide;
        tm.transparent = true;
      }
      // Environment maps from SVF turned off since we have better defaults
      // else if (texType == "generic_reflection") {
      //     mapName = "envMap";
      // }
      else {
          // no map name recognized, skip
          continue;
        }

      tm.textureMaps[map.mapName] = map;
    }

    //If the material is completely black, use a default material.
    if (d.r === 0 && d.g === 0 && d.b === 0 &&
    s.r === 0 && s.g === 0 && s.b === 0 &&
    a.r === 0 && a.g === 0 && a.b === 0 &&
    e.r === 0 && e.g === 0 && e.b === 0)
    d.r = d.g = d.b = 0.4;

    // Apply extra polygon offset to material if applicable
    // larger value means further away
    tm.extraDepthOffset = parseMaterialScalar(props, "generic_depth_offset");
    if (tm.extraDepthOffset) {
      // these values are overridden after the initial render by MaterialManager.prototype.togglePolygonOffset()
      tm.polygonOffset = true;
      tm.polygonOffsetFactor = tm.extraDepthOffset;
      tm.polygonOffsetUnits = 0;
    }

  } else
  {
    // unknown material, use default colors
    tm.ambient = new THREE.Color(0x030303);
    tm.color = new THREE.Color(0x777777);
    tm.specular = new THREE.Color(0x333333);
    tm.shininess = 30;
    tm.shading = THREE.SmoothShading;
  }

  //Add the transparent flag as a top level property of the
  //Protein JSON. This is currently how the BVH builder decides
  //whether an object is transparent. See also Package.addTransparencyFlagsToMaterials
  //which is an equivalent hack done on the web worker side.
  //Normally the BVH will be built on the worker side, so this property set here is
  //probably not needed.
  matObj.transparent = tm.transparent;

  return tm;
}

// takes a 4x4 matrix
function buildTextureTransform(mtx, trans, rotate, scale)
{
  // Build a 3D "TRS" matrix: translate, then rotate (ZYX) with the translated coordinate
  // system, then scale with the rotated coordinate system.  This mimics what is done in the
  // 3ds Max material editor.
  mtx.scale(scale);
  var s = new THREE.Vector3(Math.sin(rotate.x), Math.sin(rotate.y), Math.sin(rotate.z));
  var c = new THREE.Vector3(Math.cos(rotate.x), Math.cos(rotate.y), Math.cos(rotate.z));
  var sysx = s.y * s.x;
  var sycx = s.y * c.x;
  var rmtx = new THREE.Matrix4();
  rmtx.set(
  c.z * c.y, s.z * c.y, -s.y, 0,
  c.z * sysx - s.z * c.x, s.z * sysx + c.z * c.x, c.y * s.x, 0,
  c.z * sycx + s.z * s.x, s.z * sycx - c.z * s.x, c.y * c.x, 0,
  0, 0, 0, 1);


  rmtx.multiply(mtx);
  mtx.makeTranslation(trans.x, trans.y, trans.z);
  mtx.multiply(rmtx);
}

// we implement just the z axis rotation, since that's all that is used
function rotate_euler(z)
{
  var sz = Math.sin(z);
  var cz = Math.cos(z);

  // rotates the transform itself clockwise, meaning the texture itself will rotate counterclockwise.
  var mtx = new THREE.Matrix4();
  mtx.set(
  cz, -sz, 0, 0,
  sz, cz, 0, 0,
  0, 0, 1, 0,
  0, 0, 0, 1);


  return mtx;
}

/// Extract the texture transform matrix from prism effect instance
function extractTextureTransformByPriority(prismMaterial)
{
  // a Prism material instance could have several textures. We extract one by predefined priority
  var priorityTexture = [
  "opaque_albedo_map",
  "opaque_f0_map",
  "layered_diffuse_map",
  "layered_bottom_f0_map",
  "surface_roughness_map",
  "surface_normal_map",
  "surface_albedo_map",
  "surface_anisotropy_map",
  "surface_cutout_map"];


  if (prismMaterial.textureMaps !== undefined) {
    for (var p = 0; p < priorityTexture.length; ++p)
    {
      // check texture input exists
      if (prismMaterial.textureMaps[priorityTexture[p]] !== undefined) {
        // check texture transform exists
        if (prismMaterial.textureMaps[priorityTexture[p]].textureObj !== undefined) {
          if (prismMaterial.textureMaps[priorityTexture[p]].textureObj.matrix !== undefined) {
            var e = prismMaterial.textureMaps[priorityTexture[p]].textureObj.matrix.elements;
            var mtx = new THREE.Matrix4();
            // Convert the 3x3 to a 4x4. We need a 4x4 because we combine it with other transforms.
            // Old three.js does not have a good 3x3 matrix library.
            mtx.set(
            e[0], e[1], 0, e[2],
            e[3], e[4], 0, e[5],
            0, 0, 1, 0,
            e[6], e[7], 0, e[8]);

            return mtx;
          }
        }
      }
    }
  }

  // nothing found, return identity.
  return new THREE.Matrix4();
}

// float4x4 BuildTextureTransform(float2 offset, float3 rotation, float2 tiling, float3 center)
function buildTextureTransformOS(offset, scale)
{
  // Build a 2D texture transformation matrix that mimics what is done in the 3ds Max material
  // editor.
  // NOTE: The translation indicates an apparent shift in the image (e.g. positive x is to the
  // right), which is the opposite of translating the texture coordinates, so the translation
  // is negated here.
  //float4x4 mtx = translate(float4(-center.x, -center.y, -center.z, 0.0f));// center offset
  //mtx = mul(translate(float4(-offset.x, -offset.y, 0.0f, 0.0f)), mtx);    // translate
  //mtx = mul(scale(float4(scale.x, scale.y, 1.0f, 1.0f)), mtx);          // scale
  //mtx = mul(rotate_euler(float4(rotation, 0.0f)), mtx);                   // rotate
  //mtx = mul(translate(float4(center.x, center.y, center.z, 0.0f)), mtx);  // center restore

  var mtx = new THREE.Matrix4();
  mtx.makeScale(scale.x, scale.y, 1);
  var tmtx = new THREE.Matrix4();
  tmtx.makeTranslation(-offset.x, -offset.y, 0);
  mtx.multiply(tmtx);

  return mtx;
}

/// Compute the random axis and alignment offset for random
function computeRandomnessParameters(material, tile)
{
  // port from OGS, from https://git.autodesk.com/rapidrt/vxrender/blob/master/src/renderer/rapid_renderer/prism/nodes/TileNode.cpp. 
  // function void init(const PropertyCollectionOwner& material, Node::IDelegate& delegate)

  if (tile.randomOffsetMode === 0) return;

  material.tilingRandomAxisS = new THREE.Vector2();
  material.tilingRandomAxisT = new THREE.Vector2();
  material.tilingRandomAlignmentOffset = new THREE.Vector2();

  var outRandomAxisS = material.tilingRandomAxisS;
  outRandomAxisS.set(1.0, 0.0);
  var outRandomAxisT = material.tilingRandomAxisT;
  outRandomAxisT.set(0.0, 1.0);
  var outRandomTileAlignOffset = material.tilingRandomAlignmentOffset;
  outRandomTileAlignOffset.set(0.0, 0.0);

  // transform for tile vertices to texture space
  var xform = new THREE.Matrix4();

  // inverse matrix from texture space to random offset
  var invXform = new THREE.Matrix4();

  // get texture transform matrix from sub material effect instance
  var textureXform = extractTextureTransformByPriority(material);

  // always true: if (mFlipRandomV)
  {// TODOTODO needed? Could be the case we *don't* need to do this for WebGL.
    // if the texture flips Y, revert the flip matrix from texture transform
    var flip = buildTextureTransformOS(new THREE.Vector2(0.0, 1.0), new THREE.Vector2(1.0, -1.0));
    flip.multiply(textureXform); // sadly, there's no premultiply matrix method in r71
    textureXform.copy(flip);
  }

  // apply per-tile rotation to texture transform
  xform.multiplyMatrices(textureXform, material.tilingUVTransform);
  invXform.getInverse(xform);

  // calculate random axis in tile space by applying the inverse transform matrix to texture space axis
  var tvec3 = new THREE.Vector4(1, 0, 0, 0).applyMatrix4(invXform);
  outRandomAxisS.set(tvec3.x, tvec3.y);
  tvec3 = new THREE.Vector4(0, 1, 0, 0).applyMatrix4(invXform);
  outRandomAxisT.set(tvec3.x, tvec3.y);

  var tvec2 = new THREE.Vector2();
  // compute tile bounding box in texture space
  var bounding = new THREE.Box2();
  var verts = tile.alignedVertices;
  for (var vi = 0; vi < verts.length; vi++) {
    tvec3.set(verts[vi].x, verts[vi].y, 0, 1);
    tvec3.applyMatrix4(xform);
    tvec2.set(tvec3.x, tvec3.y);
    bounding.expandByPoint(tvec2);
  }

  // compute offset to move align tile bounding box to origin of texture in texture space, 
  // then convert back to tile space
  tvec3.set(-bounding.min.x, -bounding.min.y, 0, 0);
  tvec3.applyMatrix4(invXform);
  outRandomTileAlignOffset.set(tvec3.x, tvec3.y);

  // scale random axis for bounded random mode
  var size = bounding.size();
  var transformedTileDims = tile.randomOffsetMode === 1 ?
  new THREE.Vector2(size.x, size.y) :
  new THREE.Vector2(0, 0);
  // How much the texture can be wiggled. This axis can in fact become 0,0 due to the current algorithm.
  outRandomAxisS.multiplyScalar(1 - transformedTileDims.x);
  outRandomAxisT.multiplyScalar(1 - transformedTileDims.y);
}


// OGS equivalent: MaterialTilingPattern in MaterialTilingPattern.inl
// input JSON data is in matObj, globalTile, and the inputTiles array.
// modify the set of allocated output tile materials, adding parameters as needed.
function materialTilingPattern(matObj, globalTile, inputTiles, decals, sceneUnit) {
  // Determine global tiling values,
  // then rasterize tiles to MSDF and (optional) normal maps,
  // then fill in uniforms for each material.

  // tiling properties
  var tilingProps = globalTile["properties"];

  // We'll discard this global tiling object when done - it is just convenient to make an object created from
  // matObj's TilingPatternSchema and pass it around.
  var tiling = {
    overallTransform: new THREE.Matrix4(),
    insetSize: 0, // was: insetRadius
    hasRoundCorner: false,
    cornerRoundingAngle: 0,
    cornerRoundingSize: 0,
    offsetVectorA: new THREE.Vector2(),
    offsetVectorB: new THREE.Vector2() };

  // always set flip random offset v flag to true, because Get2DMapTransform() always flips Y. 
  var flipRandomOffsetV = true;

  var scaleFactor = new THREE.Vector2(
  parseMaterialScalarWithSceneUnit(tilingProps, "scale_factor_x", sceneUnit, 1),
  parseMaterialScalarWithSceneUnit(tilingProps, "scale_factor_y", sceneUnit, 1));


  // get overall transforms
  var overallOffsetX = parseMaterialScalarWithSceneUnit(tilingProps, "overall_offset_vector_x", sceneUnit, 1);
  var overallOffsetY = parseMaterialScalarWithSceneUnit(tilingProps, "overall_offset_vector_y", sceneUnit, 1);

  // TODOTODO - it is not documented as to what the units are for angles. Once known, add & implement
  // some form of parseMaterialScalarWithSceneUnit, but instead of it calling ConvertDistance it needs to ConvertAngle,
  // "rad" or "deg" or whatever they specify. See https://wiki.autodesk.com/display/saascore/Tiling+ProteinMaterials.json
  var overallRotateAngle = parseMaterialScalar(tilingProps, "overall_rotation_angle", 0) * Math.PI / 180.0; // degrees to radians

  buildTextureTransform(tiling.overallTransform,
  new THREE.Vector3(-overallOffsetX, -overallOffsetY, 0.0),
  new THREE.Vector3(0.0, 0.0, -overallRotateAngle),
  new THREE.Vector3(1.0, 1.0, 1.0));


  // inset size, convert to tile vertices coordinate
  tiling.insetSize = parseMaterialScalarWithSceneUnit(tilingProps, "inset_size", sceneUnit, 1);

  // corner rounding angle
  // TODOTODO - it is not documented as to what the units are for angles. Once known, add & implement this conversion function, as above
  tiling.cornerRoundingAngle = parseMaterialScalar(tilingProps, "overall_corner_rounding_angle", 0) * Math.PI / 180.0; // degrees to radians

  // corner rounding size/radius, convert to tile vertices coordinate
  tiling.cornerRoundingSize = parseMaterialScalarWithSceneUnit(tilingProps, "overall_corner_rounding_size", sceneUnit, 1);

  // repeat offset vectors
  // TODOTODO note that the current file has units, which is an error; the spec shows no units.
  // See https://wiki.autodesk.com/display/saascore/Tiling+ProteinMaterials.json
  // We (properly) ignore units here, using only the scale factors
  tiling.offsetVectorA.x = parseMaterialScalar(tilingProps, "offset_vector_a_x", 0) * scaleFactor.x;
  tiling.offsetVectorA.y = parseMaterialScalar(tilingProps, "offset_vector_a_y", 0) * scaleFactor.y;
  tiling.offsetVectorB.x = parseMaterialScalar(tilingProps, "offset_vector_b_x", 0) * scaleFactor.x;
  tiling.offsetVectorB.y = parseMaterialScalar(tilingProps, "offset_vector_b_y", 0) * scaleFactor.y;

  tiling.hasRoundCorner = tiling.cornerRoundingAngle > 0 && tiling.cornerRoundingSize > 0;

  var ti, tlen;
  // where we put the materials for the individual tilings, before "real" decals are applied
  // scale vertices directly into world units, in place
  var tiles = [];
  for (ti = 0, tlen = inputTiles.length; ti < tlen; ti++) {
    var inputTileProps = inputTiles[ti]["properties"];
    var tile = {
      material: decals[ti].material,
      randomOffsetMode: 0, // note that the material does not need to copy this value
      rotation: 0,
      vertices: [] };

    tiles[ti] = tile;
    // move tile information over from TilingAppearanceSchema
    tile.randomOffsetMode = parseMaterialGeneric(inputTileProps, "choicelists", "random_offset_mode", 0);
    // TODOTODO - it is not documented as to what the units are for angles. Once known, add & implement
    tile.rotation = parseMaterialScalar(inputTileProps, "rotation_angle", 0) * Math.PI / 180.0;
    // copy vertex array into Vector2's
    var vertList = inputTileProps["scalars"]["vertices"]["values"];
    for (var i = 0; i < vertList.length; i += 2) {
      tile.vertices[i / 2] = new THREE.Vector2(vertList[i], vertList[i + 1]);
    }

    // Use world scale factors to scale up vertices. Note there is no per-vertex-set unit scale factor.
    for (var vi = 0; vi < tile.vertices.length; vi++) {
      tile.vertices[vi].multiply(scaleFactor);
    }
    // per tile derived information; material just needs to know that random mode is on,
    // mode info is baked into the material's textures and vectors
    tile.material.useRandomOffset = tile.randomOffsetMode != 0;
  }

  // translate tile material asset and add the tile
  rasterizeTiles(tiling, tiles);

  // fill in uniforms
  var m2x2 = [tiling.offsetVectorA.clone(), tiling.offsetVectorB.clone()];
  var invM2x2 = invert(m2x2);

  for (ti = 0, tlen = tiles.length; ti < tlen; ti++) {
    var _tile = tiles[ti];
    var material = _tile.material;

    // TODOTODOTODO these should perhaps just be put in material, end of story?
    material.tilingOverallTransform = tiling.overallTransform;
    material.hasRoundCorner = tiling.hasRoundCorner;

    // per-tile texture rotation - TODO could be a 3x3, really
    material.tilingUVTransform = rotate_euler(_tile.rotation);

    if (material.useRandomOffset) {
      // compute random offset axis, and texture offset to support Bounded random mode
      computeRandomnessParameters(material, _tile);
    }

    // OGS: SetTilingParameters
    // calculate tile to uv and uv to tile transform matrixes
    material.tile2uv = new THREE.Vector4(tiling.offsetVectorA.x, tiling.offsetVectorA.y, tiling.offsetVectorB.x, tiling.offsetVectorB.y);
    material.uv2tile = new THREE.Vector4(invM2x2[0].x, invM2x2[0].y, invM2x2[1].x, invM2x2[1].y);
  }
}

function rasterizeTiles(tiling, tiles) {
  // calculate the tile repeat box, which is the parallelogram formed by repeat axis A & B
  var tileRepeatABBox = new THREE.Box2();
  tileRepeatABBox.expandByPoint(new THREE.Vector2(0.0, 0.0));
  tileRepeatABBox.expandByPoint(tiling.offsetVectorA);
  tileRepeatABBox.expandByPoint(tiling.offsetVectorB);
  var vec = new THREE.Vector2(tiling.offsetVectorA.x, tiling.offsetVectorA.y);
  vec.add(tiling.offsetVectorB);
  tileRepeatABBox.expandByPoint(vec);

  var ti, tlen;
  // for each tile
  for (ti = 0, tlen = tiles.length; ti < tlen; ti++) {
    var tile = tiles[ti];

    // compute repeat range that tile may cover the repeat ABBox
    tile.material.tilingRepeatRange = [];
    computeRange(tiling, tile, tile.material.tilingRepeatRange);

    // compute bbox - we compute this once, early on, since it is used by buildMSDFTexture
    tile.bbox = new THREE.Box2();
    for (var v = 0; v < tile.vertices.length; ++v)
    {
      tile.bbox.expandByPoint(tile.vertices[v]);
    }

    // build MSDF texture for the tile
    buildMSDFTexture(tiling, tile);

    // build normal map if it has a rounding corner
    if (tiling.hasRoundCorner) {
      buildNormalMap(tiling, tile);
    }

    // build randomness map if random is enabled
    if (tile.material.useRandomOffset) {
      buildRandomnessMap(tile, ti);
    }

    // reset tile vertices to align with tile bounding box
    tile.alignedVertices = [];
    for (var _v = 0; _v < tile.vertices.length; ++_v)
    {
      var newPoint = new THREE.Vector2(tile.vertices[_v].x, tile.vertices[_v].y);
      newPoint.sub(tile.bbox.min);
      tile.alignedVertices.push(newPoint);
    }

    // set tile alignment offset. It moves origin of tile texture UV to left-bottom corner of
    // tile bounding box. OGS calls this tileOffset
    tile.material.tileAlignOffset = new THREE.Vector2(-tile.bbox.min.x, -tile.bbox.min.y);
  }
}

function computeNormalToEdges(tile, cornerRoundingAngle)
{
  tile.normalToEdges = [];

  var edges = tile.vertices.length;

  var zComponent = Math.cos(cornerRoundingAngle);
  var factor = Math.sin(cornerRoundingAngle); // 1.0 - zComponent * zComponent;

  for (var edgeIndex = 0; edgeIndex < edges; ++edgeIndex)
  {
    var startIndex = edgeIndex;
    var endIndex = edgeIndex == edges - 1 ? 0 : edgeIndex + 1;

    var tempEdge = new THREE.Vector2(tile.vertices[endIndex].x, tile.vertices[endIndex].y);
    tempEdge.sub(tile.vertices[startIndex]);
    tempEdge.normalize();

    var normalToEdge = new THREE.Vector3(tempEdge.y * factor, -tempEdge.x * factor, zComponent);

    tile.normalToEdges.push(normalToEdge);
  }
  tile.cornerRoundingAngle = cornerRoundingAngle;
}

function distanceToSegment(q, p0, p1)
{
  var d = new THREE.Vector2(p1.x, p1.y).sub(p0);

  var qp0 = new THREE.Vector2(q.x, q.y).sub(p0);

  var t = d.dot(qp0);

  if (t <= 0.0) {
    // p0 is closest to q
    return qp0.length();
  }

  var d2 = d.dot(d);
  if (t >= d2) {
    // p1 is closest to q
    var qp1 = new THREE.Vector2(q).sub(p1);
    return qp1.length();
  }

  // otherwise closest point is interior to segment
  return Math.sqrt(Math.max(qp0.dot(qp0) - t * t / d2, 0.0));
}

// TileTexturalizer::Tile::DistanceToTile
function distanceToTileAndIndex(tile, pixelLoc)
{
  var bestDistance = 10e10;
  var currentDistance = bestDistance;

  var edgeIndex = -1;

  for (var i = 0; i < tile.vertices.length; i++) {

    var j = i == tile.vertices.length - 1 ? 0 : i + 1;

    currentDistance = distanceToSegment(pixelLoc, tile.vertices[i], tile.vertices[j]);

    if (currentDistance < bestDistance) {

      bestDistance = currentDistance;
      edgeIndex = i;
    }
  }

  return [bestDistance, edgeIndex];
}

function pointInTilePolygon(vertices, x, y)
{
  var polyCorners = vertices.length;

  var j = polyCorners - 1;
  var oddNodes = false;

  for (var i = 0; i < polyCorners; ++i) {
    if (vertices[i].y < y && vertices[j].y >= y || vertices[j].y < y && vertices[i].y >= y)
    {
      if (vertices[i].x + (y - vertices[i].y) / (vertices[j].y - vertices[i].y) * (vertices[j].x - vertices[i].x) < x)
      {
        oddNodes = !oddNodes;
      }
    }
    j = i;
  }

  return oddNodes;
}


function buildNormalMap(tiling, tile)
{
  var tileBBSize = tile.bbox.size();

  // Evaluate the size for normal map texture.
  // to make normal map provide enough precision in the case of large tile but small corner size,
  // the normal map must use enough texels to cover the rounding edge. To sample correct normal 
  // on the edge, we extend normal map out by another mCornerRoundingRadius distance. Also notice,
  // normal value is continually at the inner side of the rounding edge, but discontinuity on the 
  // outside. So we need 3 texels to cover the rounding edge. Also consider the worst case of 45
  // degree case, our final pixel size is mCornerRoundingRadius * 2.0 / sqrt(2) / 3.0;
  var pixelSize = tiling.cornerRoundingSize * 2.0 * 0.7071 / 3.0;

  // To prevent meaningless sampling on normal texture bounding, add one pixel gap on each bound.
  // also, to prevent too small or too large texture size, limit texture size between 128 and 1024
  var width = clamp(Math.ceil(tileBBSize.x / pixelSize) + 2, 128, 1024);
  var height = clamp(Math.ceil(tileBBSize.y / pixelSize) + 2, 128, 1024);

  // Adjust the width or height so the ratio is the same as tileBBSize.
  {
    // leave out one pixel on boundary
    width -= 2;
    height -= 2;
    if (tileBBSize.x < tileBBSize.y)
    {
      // scale to keep width/height factor
      height = Math.floor(width * tileBBSize.y / tileBBSize.x);
    } else

    {
      // scale to keep width/height factor
      width = Math.floor(height * tileBBSize.x / tileBBSize.y);
    }

    // add back bounding pixel
    width += 2;
    height += 2;
  }

  computeNormalToEdges(tile, tiling.cornerRoundingAngle);

  var pixels = new Uint8Array(width * height * 4);

  // scale factor from tile vertices to image
  // scaled in one pixel each side for correct bilinear sampling on bound
  var scale = new THREE.Vector2(tileBBSize.x / (width - 2), tileBBSize.y / (height - 2));

  var pixelLoc = new THREE.Vector2();
  var pointNormal = new THREE.Vector3();
  var defaultNormal = new THREE.Vector3(0.0, 0.0, 1.0);

  //console.log("P3\n" + width + " " + height + "\n255");
  var idx = 0;
  for (var i = 0; i < height; i++)
  {
    // Begin from -1 for the one pixel left out along the edge. Move 0.5f to center the texel.
    // NOTE: we do a y reverse here for the texture, OpenGL-style. DX is simply "(i - 0.5)".
    pixelLoc.y = (height - i - 1.5) * scale.y + tile.bbox.min.y;

    for (var j = 0; j < width; j++)
    {
      // Begin from -1 for the one pixel left out. Move 0.5f to center the texel. 
      pixelLoc.x = (j - 0.5) * scale.x + tile.bbox.min.x;

      // we always calculate distance to tile for all pixels, but only write down normal into
      // normal map for distance less than corner rounding radius, so that we can have smooth
      // normal from tile center to edge. We add negative flag to pixels that out of polygon, 
      // so that we would have correct interpreted value on edge
      var dttei = distanceToTileAndIndex(tile, pixelLoc);
      var distanceToTile = dttei[0];
      var edgeIndex = dttei[1];
      if (distanceToTile < tiling.cornerRoundingSize + tiling.insetSize)
      {
        if (!pointInTilePolygon(tile.vertices, pixelLoc.x, pixelLoc.y))
        {
          distanceToTile = -distanceToTile;
        }
        pointNormal.copy(tile.normalToEdges[edgeIndex]);
        pointNormal.lerp(defaultNormal, (distanceToTile - tiling.insetSize) / tiling.cornerRoundingSize);
        pointNormal.normalize();

        // to avoid value flow out of 8-bit storage, we save normalized pointNormal in 
        // normal map. In pixel shader, we need minus (0,0,1) to get normal diff, then 
        // apply the normal diff to geometry normal
        // Note that Math.int is implied, as these get stored in unsigned ints
        pixels[idx++] = clamp((pointNormal.x + 1.0) * 0.5, 0.0, 1.0) * 255;
        pixels[idx++] = clamp((pointNormal.y + 1.0) * 0.5, 0.0, 1.0) * 255;
        pixels[idx++] = clamp((pointNormal.z + 1.0) * 0.5, 0.0, 1.0) * 255;
        pixels[idx++] = 255;
      } else {
        pixels[idx++] = 127;
        pixels[idx++] = 127;
        pixels[idx++] = 255;
        pixels[idx++] = 255;
      }
      //console.log(pixels[idx-4] + " " + pixels[idx-3] + " " + pixels[idx-2] + " ");
    }
  }
  //console.log("============ NORMAL BREAK ==============");

  // https://developer.mozilla.org/en-US/docs/Web/API/WebGLRenderingContext/texParameter
  // there is no "border color" supported for textures in WebGL, no BorderColor(OGS::float4(0.5f, 0.5f, 0.5f, 1.0f)
  // which would call mCtx->GLAPI()->glTexParameterfv(mObjTarget,_kGL_TEXTURE_BORDER_COLOR, border);


  // Create tile pattern texture
  tile.material.TilingNormalMap = new THREE.DataTexture(pixels, width, height, THREE.RGBAFormat, THREE.UnsignedByteType, THREE.UVMapping,
  THREE.ClampToEdgeWrapping, THREE.ClampToEdgeWrapping,
  THREE.LinearFilter, THREE.LinearFilter);
  // You'd think this would be the default setting for a new DataTexture. You'd be wrong. Without it the texture will not get loaded.
  tile.material.TilingNormalMap.needsUpdate = true;

  var tileBBOffset = new THREE.Vector2(-tile.bbox.min.x, -tile.bbox.min.y);
  tile.material.TilingNormalMap_texMatrix = new THREE.Matrix3();
  // note that the original C++ code assumes row-major form (translations in the bottom row), while three.js
  // assumes column-major, though of course internally putting the translations in the last 4 spots in the array.
  // Rather than mess with the code, we keep the row-major form here, and then transpose.
  // NOTE: we use a 3x3 transform here, unlike OGS.
  tile.material.TilingNormalMap_texMatrix.set(
  1.0 / tileBBSize.x * (width - 2.0) / width, 0.0, 0.0,
  0.0, 1.0 / tileBBSize.y * (height - 2.0) / height, 0.0,
  //0.0, 0.0, 1.0, 0.0,
  tileBBOffset.x / tileBBSize.x * (width - 2.0) / width + 1.0 / width,
  tileBBOffset.y / tileBBSize.y * (height - 2.0) / height + 1.0 / height,
  1.0);

  tile.material.TilingNormalMap_texMatrix.transpose();
}

function burtlerot(x, k) {
  return x << k | x >>> 32 - k; // note >>> if you ever touch this code: need this zero-fill shift for unsigned ints
}

function burtlefinal(a, b, c) {
  var fullbits = 4294967296; // 2**32
  c ^= b;c = (c - burtlerot(b, 14) + fullbits) % fullbits;
  a ^= c;a = (a - burtlerot(c, 11) + fullbits) % fullbits;
  b ^= a;b = (b - burtlerot(a, 25) + fullbits) % fullbits;
  c ^= b;c = (c - burtlerot(b, 16) + fullbits) % fullbits;
  a ^= c;a = (a - burtlerot(c, 4) + fullbits) % fullbits;
  b ^= a;b = (b - burtlerot(a, 14) + fullbits) % fullbits;
  c ^= b;c = (c - burtlerot(b, 24) + fullbits) % fullbits;
  return c;
}

function burtlehashword(
key, /* the key */
//we assume key length of 2, as this is how OGS always uses it
//size_t          length,               /* the length of the key, in uint32_ts */
initval) /* the previous hash, or an arbitrary value */
{
  var a, b, c;

  /* Set up the internal state */
  a = b = c = 0xdeadbeef + (2 << 2) + initval;

  b += key.y;
  a += key.x;
  return burtlefinal(a, b, c);
}

// stripped way down from the OGS version, which always uses a seed array of size 2.
// From //depot/Raas/current/rsut/include/rsut/detail/burtle_hash_impl.hpp
function burtleNoise2Byte(seed2, result2)
{
  var hash = burtlehashword(seed2, 33);

  // low 16bit for x, and high 16bit for y, converted to 0-255 pixel values
  result2.x = (hash & 0xFFFF) >>> 8;
  result2.y = hash >>> 16 >>> 8;
}


function buildRandomnessMap(tile, seed)
{
  var width = 512;
  var height = 512;
  var PRIME = 107021;

  var pixels = new Uint8Array(width * height * 4);

  var pixelLoc = new THREE.Vector2();

  var seedVector = new THREE.Vector2(PRIME * seed, seed);
  var hashTranslationID = new THREE.Vector2();
  var randomOffset = new THREE.Vector2();

  //console.log("P3\n" + width + " " + (height/16) + "\n255");
  var idx = 0;
  for (var i = 0; i < height; i++)
  {
    // Begin from -1 for the one pixel left out along the edge. Move 0.5f to center the texel.
    // Move (0,0) to the center of texture
    // NOTE: we do a y reverse here for the texture, OpenGL-style. DX is simply "i - height / 2".
    //pixelLoc.y = Math.floor(i - height / 2); // Original code. For testing against OGS
    pixelLoc.y = Math.floor(-(i + 1 - height / 2));

    for (var j = 0; j < width; j++)
    {
      // Move (0,0) to the center of texture 
      pixelLoc.x = Math.floor(j - width / 2);

      hashTranslationID.copy(pixelLoc);
      hashTranslationID.add(seedVector);
      burtleNoise2Byte(hashTranslationID, randomOffset);

      pixels[idx++] = 0; // unused byte
      pixels[idx++] = 0; // unused byte
      pixels[idx++] = randomOffset.x;
      pixels[idx++] = randomOffset.y;
      //if ( i % 16 === 0 )
      //    console.log(pixels[idx-3] + " " + pixels[idx-2] + " " + pixels[idx-1] + " ");
    }
  }
  //console.log("============ NORMAL BREAK ==============");

  // Create tile pattern texture
  tile.material.TilingRandomMap = new THREE.DataTexture(pixels, width, height, THREE.RGBAFormat, THREE.UnsignedByteType, THREE.UVMapping,
  THREE.RepeatWrapping, THREE.RepeatWrapping,
  THREE.NearestFilter, THREE.NearestFilter);
  // You'd think this would be the default setting for a new DataTexture. You'd be wrong. Without it the texture will not get loaded.
  tile.material.TilingRandomMap.needsUpdate = true;

  var tileBBOffset = new THREE.Vector2(-tile.bbox.min.x, -tile.bbox.min.y);
  tile.material.TilingRandomMap_texMatrix = new THREE.Matrix3();
  // note that the original C++ code assumes row-major form (translations in the bottom row), while three.js
  // assumes column-major, though of course internally putting the translations in the last 4 spots in the array.
  // Rather than mess with the code, we keep the row-major form here, and then transpose.
  // NOTE: we use a 3x3 transform here, unlike OGS.
  tile.material.TilingRandomMap_texMatrix.set(
  1.0 / width, 0.0, 0.0,
  0.0, 1.0 / height, 0.0,
  //0.0, 0.0, 1.0, 0.0,
  0.5, 0.5, 1.0);

  tile.material.TilingRandomMap_texMatrix.transpose();
}

function computeRange(tiling, tile, range)
{
  // convert all tile vertices to repeat space, then compute bounding box in repeat space.
  // The repeat space is a 2D space by repeat vector mAxisA and mAxisB as its axis.
  var repeat2tile = [];
  repeat2tile[0] = new THREE.Vector2(tiling.offsetVectorA.x, tiling.offsetVectorB.x);
  repeat2tile[1] = new THREE.Vector2(tiling.offsetVectorA.y, tiling.offsetVectorB.y);
  var tile2repeat = invert(repeat2tile);

  var bounding = new THREE.Box2();

  var vertInRepeatSpace = new THREE.Vector2();
  for (var v = 0; v < tile.vertices.length; ++v) {
    vertInRepeatSpace.x = tile.vertices[v].dot(tile2repeat[0]);
    vertInRepeatSpace.y = tile.vertices[v].dot(tile2repeat[1]);

    bounding.expandByPoint(vertInRepeatSpace);
  }

  // compute the offset range, that,
  //     uv + offset = st, where uv belongs to [0, 1]x[0, 1]
  //                             st belongs to bounding of tile
  // This code is more efficient, but fails in a tiny way for
  // OGS test Protein_Material_PrismTiling_TwoObj_Random1
  //let epsilon = 1e-6;
  //range[0] = Math.floor(bounding.min.x + epsilon);
  //range[2] = Math.ceil(bounding.max.x - epsilon);
  //range[1] = Math.floor(bounding.min.y + epsilon);
  //range[3] = Math.ceil(bounding.max.y - epsilon);
  // OGS method. I suspect adding & subtracting epsilon, as above, can be more efficient
  // if there are precision problems.
  range[0] = Math.floor(bounding.min.x - 1);
  range[2] = Math.ceil(bounding.max.x);
  range[1] = Math.floor(bounding.min.y - 1);
  range[3] = Math.ceil(bounding.max.y);
}

function invert(m)
{
  var det = m[0].x * m[1].y - m[0].y * m[1].x;
  var inverse = [];
  inverse[0] = new THREE.Vector2(m[1].y / det, -m[0].y / det);
  inverse[1] = new THREE.Vector2(-m[1].x / det, m[0].x / det);
  return inverse;
}

function clamp(v, min, max) {
  if (v > max)
  return max;
  if (v < min)
  return min;
  return v;
}

function constructMSDF(shape, tile)
{
  // construct MSDF sharp by adding tile polygon as a contor of MSDF
  var contour = shape.addBlankContour();
  var edgeStartPoint = new THREE.Vector2();
  var edgeEndPoint = new THREE.Vector2();
  for (var e = 0; e < tile.vertices.length; ++e)
  {
    var edgeStart = e;
    var edgeEnd = (e + 1) % tile.vertices.length;

    edgeStartPoint = tile.vertices[edgeStart];
    edgeEndPoint = tile.vertices[edgeEnd];

    contour.addEdge(new MSDFLinearSegment(edgeStartPoint, edgeEndPoint, MSDF_EDGE_COLOR_WHITE));
  }

  shape.initialize();
  shape.edgeColoringSimple(3.0, 0);
}

function buildMSDFTexture(tiling, tile)
{
  // construct the shape of MSDF
  var msdfShape = new MSDFShape();
  constructMSDF(msdfShape, tile);

  // rasterize tile into MSDF texture
  var tileBBSize = tile.bbox.size();

  // Evaluate the size for MSDF texture.
  // to prevent MSDF value overlap, the MSDF texture must use more than two texels to cover the
  // shortest distance between any two same colored edges. The shortest distance would face to 
  // arbitrary direction, we need consider the worst case, the 45 degree direction. So here use
  // msdfShape.MinSameColoredEdgeDistance() / sqrt(2) as the minimum same colored edge distance
  // on image x and y direction. The direction must cover at least two texels, so our final pixel
  // size is msdfShape.MinSameColoredEdgeDistance() / sqrt(2) / 2.0
  var pixelSize = msdfShape.minSameColoredEdgeDistance() * 0.7071 * 0.5;
  // The final texture size is the tile region to be texturalized divide pixel size. 
  // notice warp repeat on tile texture does not make sense. To prevent meaningless sampling on
  // tile texture bounding, add one pixel gap on each bound.
  // also, to prevent too small or too large texture size, limit texture size between 16 and 512.
  var width = clamp(Math.ceil(tileBBSize.x / pixelSize) + 2, 16, 512);
  var height = clamp(Math.ceil(tileBBSize.y / pixelSize) + 2, 16, 512);

  // use RGBA8 formated texture
  //    EFormat format = vd.Caps()->GetCompatibleFormat(EFORMAT_B8G8R8A8, TextureUsage);
  //    int bytesPerPixel = AFormatConvertor::BytesPerPixel(format);

  //    int bytesPerRow = width * bytesPerPixel;
  //    size_t totalImageSize = bytesPerRow*height;
  //    unsigned char* pImageData = new unsigned char[totalImageSize];
  //    int pixelOffset = bytesPerPixel;
  var pixels = new Uint8Array(width * height * 4);

  // leave out one pixel each side for correct bilinear sampling on bound
  var scale = new THREE.Vector2(tileBBSize.x / (width - 2), tileBBSize.y / (height - 2));
  var imageToTileScale = new THREE.Vector2(scale.x * width, scale.y * height);

  //    unsigned char *pixel = pImageData;

  // factor for cut out pixel values in MSDF texture
  var oneOverDistanceUnit = 0.5 / (scale.x + scale.y);

  var pixelLoc = new THREE.Vector2();
  var msdf = new THREE.Vector3();

  var idx = 0;
  //console.log("P3\n" + width + " " + height + "\n255");
  for (var i = 0; i < height; i++)
  {
    // Begin from -1 for the one pixel left out along the edge. Move 0.5f to center the texel.
    // NOTE: we do a y reverse here for the texture, OpenGL-style. DX is simply "(i - 0.5)".
    pixelLoc.y = (height - i - 1.5) * scale.y + tile.bbox.min.y;

    for (var j = 0; j < width; j++)
    {
      // Begin from -1 for the one pixel left out. Move 0.5f to center the texel. 
      pixelLoc.x = (j - 0.5) * scale.x + tile.bbox.min.x;

      // get the MSDF value for (x,y)
      msdf = msdfShape.calculateMSDFValue(pixelLoc);

      // considering inset, the real tile edge is the distance to tile edge minus inset size.
      // our msdf is always negative value for inside pixels, so final msdf is msdf+insetSize.
      msdf = msdf.add(new THREE.Vector3(tiling.insetSize, tiling.insetSize, tiling.insetSize));

      // compact float value into 8-bit int
      pixels[idx++] = clamp(msdf.x * oneOverDistanceUnit + 0.5, 0.0, 1.0) * 255.0;
      pixels[idx++] = clamp(msdf.y * oneOverDistanceUnit + 0.5, 0.0, 1.0) * 255.0;
      pixels[idx++] = clamp(msdf.z * oneOverDistanceUnit + 0.5, 0.0, 1.0) * 255.0;
      pixels[idx++] = 0;
      //console.log(pixels[idx-4] + " " + pixels[idx-3] + " " + pixels[idx-2] + " ");
    }
  }

  // Create tile pattern texture
  tile.material.TilingMap = new THREE.DataTexture(pixels, width, height, THREE.RGBAFormat, THREE.UnsignedByteType, THREE.UVMapping,
  THREE.ClampToEdgeWrapping, THREE.ClampToEdgeWrapping,
  THREE.LinearFilter, THREE.LinearFilter);
  // You'd think this would be the default setting for a new DataTexture. You'd be wrong. Without it the texture will not get loaded.
  tile.material.TilingMap.needsUpdate = true;

  // https://developer.mozilla.org/en-US/docs/Web/API/WebGLRenderingContext/texParameter
  // there is no "border color" supported for textures in WebGL, no BorderColor(OGS::float4(1.0f, 1.0f, 1.0f, 1.0f)
  // which would call mCtx->GLAPI()->glTexParameterfv(mObjTarget,_kGL_TEXTURE_BORDER_COLOR, border);

  var tileBBOffset = new THREE.Vector2(-tile.bbox.min.x, -tile.bbox.min.y);
  tile.material.TilingMap_texMatrix = new THREE.Matrix3();
  // note that the original C++ code assumes row-major form (translations in the bottom row), while three.js
  // assumes column-major, though of course internally putting the translations in the last 4 spots in the array.
  // Rather than mess with the code, we keep the row-major form here, and then transpose.
  // NOTE: we use a 3x3 transform here, unlike OGS.
  tile.material.TilingMap_texMatrix.set(
  1.0 / tileBBSize.x * (width - 2.0) / width, 0.0, 0.0,
  0.0, 1.0 / tileBBSize.y * (height - 2.0) / height, 0.0,
  //0.0, 0.0, 1.0, 0.0,
  tileBBOffset.x / tileBBSize.x * (width - 2.0) / width + 1.0 / width,
  tileBBOffset.y / tileBBSize.y * (height - 2.0) / height + 1.0 / height,
  1.0);

  // normally we would set the matrix above with the translations in the column, but we match the code in OGS for
  // maintainability, so we need to transpose here.
  tile.material.TilingMap_texMatrix.transpose();
}


function convertPrismTexture(textureObj, texture, sceneUnit) {

  var texProps = textureObj["properties"];

  // Note that the format of these booleans is different for Protein than for regular materials:
  // Prism: "texture_URepeat": { "values": [ false ] },
  // simple texture: "texture_URepeat":    false,
  texture.clampS = !parseMaterialGeneric(texProps, "booleans", "texture_URepeat", false);
  texture.clampT = !parseMaterialGeneric(texProps, "booleans", "texture_VRepeat", false);
  texture.wrapS = !texture.clampS ? THREE.RepeatWrapping : THREE.ClampToEdgeWrapping;
  texture.wrapT = !texture.clampT ? THREE.RepeatWrapping : THREE.ClampToEdgeWrapping;

  texture.matrix = textureObj.matrix || (textureObj.matrix = Get2DPrismMapTransform(texProps, sceneUnit));

  if (textureObj["definition"] == "UnifiedBitmap") {
    texture.invert = parseMaterialGeneric(texProps, "booleans", "unifiedbitmap_Invert", false);
  }

  if (textureObj["definition"] == "BumpMap") {
    texture.bumpmapType = parseMaterialGeneric(texProps, "choicelists", "bumpmap_Type", 0);
    texture.bumpScale = GetBumpScale(texProps, texture.bumpmapType, sceneUnit);
  }

}

function Get2DSimpleMapTransform(texProps) {
  var uscale = parseMaterialScalar(texProps, "texture_UScale", 1);
  var vscale = parseMaterialScalar(texProps, "texture_VScale", 1);
  var uoffset = parseMaterialScalar(texProps, "texture_UOffset", 0);
  var voffset = parseMaterialScalar(texProps, "texture_VOffset", 0);
  var wangle = parseMaterialScalar(texProps, "texture_WAngle", 0);

  return { elements: [
    Math.cos(wangle) * uscale, Math.sin(wangle) * vscale, 0,
    -Math.sin(wangle) * uscale, Math.cos(wangle) * vscale, 0,
    uoffset, voffset, 1] };

}

function convertSimpleTexture(textureObj, texture) {

  if (!textureObj)
  return;

  var texProps = textureObj["properties"];

  // Note that the format of these booleans is different for Protein than for regular materials:
  // Prism: "texture_URepeat": { "values": [ false ] },
  // simple texture: "texture_URepeat":    false,
  texture.invert = parseMaterialBoolean(texProps, "unifiedbitmap_Invert");
  texture.clampS = !parseMaterialBoolean(texProps, "texture_URepeat", true); // defaults to wrap
  texture.clampT = !parseMaterialBoolean(texProps, "texture_VRepeat", true);
  texture.wrapS = !texture.clampS ? THREE.RepeatWrapping : THREE.ClampToEdgeWrapping;
  texture.wrapT = !texture.clampT ? THREE.RepeatWrapping : THREE.ClampToEdgeWrapping;

  texture.matrix = textureObj.matrix || (textureObj.matrix = Get2DSimpleMapTransform(texProps));
}

function get2DMapTransform(textureObj, isPrism, sceneUnit) {
  if (!textureObj.matrix) {
    if (isPrism) {
      textureObj.matrix = Get2DPrismMapTransform(textureObj.properties, sceneUnit);
    } else {
      textureObj.matrix = Get2DSimpleMapTransform(textureObj.properties);
    }
  }
  return textureObj.matrix;
}

function convertTexture(textureDef, texture, sceneUnit, maxAnisotropy) {

  if (textureDef.mapName == "bumpMap" || textureDef.mapName == "normalMap") {
    texture.anisotropy = 0;
  } else {
    texture.anisotropy = maxAnisotropy || 0;
  }

  // Default params
  texture.flipY = textureDef.flipY !== undefined ? textureDef.flipY : true;
  texture.invert = false;
  texture.wrapS = THREE.RepeatWrapping;
  texture.wrapT = THREE.RepeatWrapping;

  //Per material type settings
  if (textureDef.isPrism)
  convertPrismTexture(textureDef.textureObj, texture, sceneUnit);else

  convertSimpleTexture(textureDef.textureObj, texture);

  // semi-fix for LMV-1832 - doesn't work for procedural wood, though.
  // if ( av.isIE11 && textureDef.isPrism ) {
  //      for (var i = 0; i < 4; i++)
  //          texture.matrix.elements[(i<2)?i:(i+1)] *= 0.5;  // elements 0,1,3,4
  // }
}


function isPrismMaterial(material) {
  var innerMats = material['materials'];
  var innerMat = innerMats[material['userassets'][0]];
  if (innerMat) {
    var definition = innerMat['definition'];
    if (definition === 'TilingPattern') {
      // if first "material" is a tiling pattern, look at the grout material, which must always exist.
      var idx = innerMat.properties.references.grout_material.connections[0];
      innerMat = innerMats[idx];
      if (innerMat) {
        definition = innerMat['definition'];
      } // else it stays TilingPattern and will fail below
    }
    return definition == 'PrismLayered' ||
    definition == 'PrismMetal' ||
    definition == 'PrismOpaque' ||
    definition == 'PrismTransparent' ||
    definition == 'PrismGlazing' ||
    definition == 'PrismWood';
  }
  return false;
}

function hasTiling(material) {
  var innerMats = material['materials'];
  var innerMat = innerMats[material['userassets'][0]];
  if (innerMat) {
    var definition = innerMat['definition'];
    if (definition === 'TilingPattern') {
      return true;
    }
  }
  return false;
}

function convertMaterialGltf(matObj, svf) {

  var tm = new THREE.MeshPhongMaterial();
  tm.packedNormals = true;
  tm.textureMaps = {};

  var values = matObj.values;

  var diffuse = values.diffuse;
  if (diffuse) {
    if (Array.isArray(diffuse)) {
      tm.color = new THREE.Color(diffuse[0], diffuse[1], diffuse[2]);
    } else if (typeof diffuse === "string") {
      //texture
      tm.color = new THREE.Color(1, 1, 1);
      var map = {};
      map.mapName = "map";

      var texture = svf.gltf.textures[diffuse];

      //Use the ID of the texture, because in MaterialManager.loadTexture, the ID
      //is mapped to the path from the asset list. The logic matches what is done
      //with SVF materials.
      map.uri = texture.source; //svf.manifest.assetMap[texture.source].URI;
      map.flipY = false; //For GLTF, texture flip is OpenGL style by default, unlike Protein/Prism which is DX

      tm.textureMaps[map.mapName] = map;
    }
  }

  var specular = values.specular;
  if (specular) {
    tm.specular = new THREE.Color(specular[0], specular[1], specular[2]);
  }

  if (values.shininess)
  tm.shininess = values.shininess;

  tm.reflectivity = 0;

  //TODO: Where to get this for glTF materials?
  tm.transparent = false;

  return tm;

}


//Using post-gamma luminance, since input colors are assumed to
//have gamma (non-linearized).
function luminance(c) {
  return 0.299 * c.r + 0.587 * c.g + 0.114 * c.b;
}


function applyAppearanceHeuristics(mat, skipSimplePhongSpecific, depthWriteTransparent) {

  var proteinMaterial = mat.proteinMat ? mat.proteinMat : null;

  var isPrism = mat.prismType && mat.prismType.indexOf("Prism") !== -1;
  if (isPrism && mat.transparent) {
    // currently Fusion objects come in as double-sided. Once ATF and Fusion fix this, they
    // can come in as single-sided. For PRISM materials that are transparent, make these
    // always be double sided, so they render properly in two passes, back and front displayed.
    // The side for PrismGlazing materials is set from glazing_backface_culling property
    // so don't override it here.
    if (mat.side === THREE.FrontSide && mat.prismType != "PrismGlazing")
    mat.side = THREE.DoubleSide;

    // Add a flag that notes that two-pass transparency is to be used. This is meant for Fusion in
    // particular, where transparent objects are rendered in two passes, back faces then front faces.
    // This can cause problems with other, arbitrary geometry, such as found in
    // https://jira.autodesk.com/browse/LMV-1121.
    // If we want to extend this two-pass rendering method to all materials, we have to come up
    // with some rules for how to differentiate data here.
    if (mat.side === THREE.DoubleSide && mat.depthTest)
    mat.twoPassTransparency = true;
    //else
    //    mat.twoPassTransparency = false;
  }

  var maps = mat.textureMaps || {};

  //apply various modifications to fit our rendering pipeline
  if (!skipSimplePhongSpecific) {

    //Is it a SimplePhong which was converted from a Prism source?
    var isSimpleFromPrism = mat.proteinType && mat.proteinType.indexOf("Prism") !== -1;

    //This pile of crazy hacks maps the various flavors of materials
    //to the shader parameters that we can handle.

    if (mat.metal) {

      if (!mat.reflectivity) {
        mat.reflectivity = luminance(mat.specular);
      }

      //Special handling for Protein and Prism metals
      if (proteinMaterial)
      {
        //For Prism metals, reflectivity is set to 1 and
        //the magnitude of the specular component acts
        //as reflectivity.
        if (mat.reflectivity === 1)
        mat.reflectivity = luminance(mat.specular);

        if (mat.color.r === 0 && mat.color.g === 0 && mat.color.b === 0) {
          //Prism metals have no diffuse at all, but we need a very small
          //amount of it to look reasonable
          //mat.color.r = mat.specular.r * 0.1;
          //mat.color.g = mat.specular.g * 0.1;
          //mat.color.b = mat.specular.b * 0.1;
        } else
        {
          //For Protein metals, we get a diffuse that is full powered, so we
          //scale it down
          mat.color.r *= 0.1;
          mat.color.g *= 0.1;
          mat.color.b *= 0.1;
        }
      }
    } else
    {
      //Non-metal materials

      if (isSimpleFromPrism)
      {
        var isMetallic = false;

        if (mat.proteinType === "PrismLayered")
        {
          //For layered materials, the Prism->Simple translator
          //stores something other than reflectivity in the
          //reflectivity term. We also do special handling
          //for paint clearcoat, and metallic paint. Longer term,
          //the good solution is to add things we do support to the Simple
          //representation, or failing that, support native Prism definitions.
          mat.clearcoat = true;
          mat.reflectivity = 0.06;

          var cats = mat.proteinCategories;
          if (cats && cats.length && cats[0].indexOf("Metal") != -1)
          {
            isMetallic = true;
          }
        }

        //De-linearize this value in case of Prism, since there it
        //seems to be physical (unlike the color values)
        mat.reflectivity = Math.sqrt(mat.reflectivity);

        if (isMetallic)
        {
          //metallic paint has specular = diffuse in Prism.
          mat.specular.copy(mat.color);
        } else

        {
          //Prism non-metals just leave the specular term as 1,
          //relying on reflectivity alone, but our shader needs
          //both in different code paths.
          mat.specular.r = mat.reflectivity;
          mat.specular.g = mat.reflectivity;
          mat.specular.b = mat.reflectivity;
        }
      } else

      {
        //Get a reasonable reflectivity value if there isn't any
        if (!mat.reflectivity) {
          if (mat.color.r === 1 && mat.color.g === 1 && mat.color.b === 1 &&
          mat.specular.r === 1 && mat.specular.g === 1 && mat.specular.b === 1 &&
          !maps.map && !maps.specularMap)
          {
            //This covers specific cases in DWF where metals get diffuse=specular=1.
            mat.metal = true;
            mat.reflectivity = 0.7;

            mat.color.r *= 0.1;
            mat.color.g *= 0.1;
            mat.color.b *= 0.1;
          } else {

            //General case
            //For non-metallic materials, reflectivity
            //varies very little in the range 0.03-0.06 or so
            //and is never below 0.02.
            mat.reflectivity = 0.01 + 0.06 * luminance(mat.specular);

            //For non-metals, reflectivity is either set
            //correctly or we estimate it above, and the specular color
            //just carries the hue
            //Note: Protein (but not Prism) seems to have consistently high reflectivity
            //values for its non-metals.
            mat.specular.r *= mat.reflectivity;
            mat.specular.g *= mat.reflectivity;
            mat.specular.b *= mat.reflectivity;
          }

        } else if (mat.reflectivity > 0.3) {
          //If reflectivity is set explicitly to a high value, but metal is not, assume
          //the material is metallic anyway and set specular=diffuse
          //This covers specific cases in DWF.

          mat.metal = true;
          mat.specular.r = mat.color.r;
          mat.specular.g = mat.color.g;
          mat.specular.b = mat.color.b;

          mat.color.r *= 0.1;
          mat.color.g *= 0.1;
          mat.color.b *= 0.1;
        } else {
          //For non-metals, reflectivity is either set
          //correctly or we estimate it above, and the specular color
          //just carries the hue
          //Note: Protein (but not Prism) seems to have consistently high reflectivity
          //values for its non-metals.
          mat.specular.r *= mat.reflectivity;
          mat.specular.g *= mat.reflectivity;
          mat.specular.b *= mat.reflectivity;
        }

        //For transparent non-layered materials, the reflectivity uniform is
        //used for scaling the Fresnel reflection at oblique angles
        //This is a non-physical hack to make stuff like ghosting
        //look reasonable, while having glass still reflect at oblique angles
        if (mat.opacity < 1)
        mat.reflectivity = 1.0;
      }
    }

    //Alpha test for materials with textures that are potentially opacity maps
    if (mat.transparent ||
    maps.map && maps.map.uri.toLowerCase().indexOf(".png") !== -1 ||
    maps.alphaMap) {
      mat.alphaTest = 0.01;
    }
  }

  if (maps.normalMap)
  {
    var scale = mat.bumpScale;
    if (scale === undefined || scale >= 1)
    scale = 1;

    mat.normalScale = new THREE.Vector2(scale, scale);
  } else

  {
    if (mat.bumpScale === undefined && (maps.map || maps.bumpMap))
    mat.bumpScale = 0.03; //seems like a good subtle default if not given
    else if (mat.bumpScale >= 1) //Protein generic mat sometimes comes with just 1.0 which can't be right...
        mat.bumpScale = 0.03;
  }


  //Determine if we want depth write on for transparent materials
  //This check is done this way because for the ghosting and selection materials
  //we do not want to enable depth write regardless of what we do for the others
  //in order to get the see-through effect.
  if ((!skipSimplePhongSpecific || isPrism) && mat.transparent) {
    if (isPrism) {
      // normally set depth writing off for transparent surfaces
      mat.lmv_depthWriteTransparent = true;
      mat.depthWrite = !!depthWriteTransparent;
    } else {

      // Some models, such as Assembly_Chopper.svf, improperly are set to be transparent, even though the
      // surface opacity is 1.0.
      // Cutout textures (where opacity is also 1.0) should also not be considered transparent,
      // as far as depthWrite goes.
      if (mat.opacity >= 1.0) {
        var hasAlphaTexture = maps.alphaMap;
        // this is either a surface with a cutout texture, or a defective material definition
        if (!hasAlphaTexture) {
          // defective - turn transparency off
          mat.transparency = false;
        }
        // else cutout detected: leave transparency on, leave depthWrite on
      } else {
        // opacity is less than 1, so this surface is meant to be transparent - turn off depth depthWrite
        mat.lmv_depthWriteTransparent = true;
        mat.depthWrite = !!depthWriteTransparent;
      }
    }
  }

  if (mat.shininess !== undefined)
  {
    //Blinn to Phong (for blurred environment map sampling)
    mat.shininess *= 0.25;
  }

  //if (mat.opacity < 1.0 || maps.alphaMap)
  //    mat.side = THREE.DoubleSide;

}



//Certain material properties only become available
//once we see a geometry that uses the material. Here,
//we modify the material based on a given geometry that's using it.
function applyGeometryFlagsToMaterial(material, threegeom) {

  if (threegeom.attributes.color) {
    //TODO: Are we likely to get the same
    //material used both with and without vertex colors?
    //If yes, then we need two versions of the material.
    material.vertexColors = THREE.VertexColors;
    material.needsUpdate = true;
  }

  //If we detect a repeating texture in the geometry, we assume
  //it is some kind of material roughness pattern and reuse
  //the texture as a low-perturbation bump map as well.
  if (!material.proteinType && threegeom.attributes.uv && threegeom.attributes.uv.isPattern) {
    var setBumpScale = false;
    if (material.map && !material.bumpMap) {
      material.bumpMap = material.map;
      material.needsUpdate = true;
      setBumpScale = true;
    }
    if (material.textureMaps && material.textureMaps.map && !material.textureMaps.bumpMap) {
      material.textureMaps.bumpMap = material.textureMaps.map;
      material.needsUpdate = true;
      setBumpScale = true;
    }
    if (setBumpScale && material.bumpScale === undefined)
    material.bumpScale = 0.03; //seems like a good subtle default if not given
  }

}


export var MaterialConverter = {
  convertMaterial: convertMaterial,
  materialTilingPattern: materialTilingPattern,
  convertTexture: convertTexture,
  get2DMapTransform: get2DMapTransform,
  isPrismMaterial: isPrismMaterial,
  hasTiling: hasTiling,
  convertMaterialGltf: convertMaterialGltf,
  applyAppearanceHeuristics: applyAppearanceHeuristics,
  applyGeometryFlagsToMaterial: applyGeometryFlagsToMaterial,
  swapPrismWoodTextures: swapPrismWoodTextures,
  disposePrismWoodTextures: disposePrismWoodTextures };