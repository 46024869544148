
import { isNodeJS, getGlobal } from "../../compat";

var _window = getGlobal();

var endp = {};

var CDN_ROOT = null;
endp.ENDPOINT_API_DERIVATIVE_SERVICE_V2 = 'derivativeV2';
endp.ENDPOINT_API_MODEL_DERIVATIVE_V2 = 'modelDerivativeV2'; // Forge
endp.ENDPOINT_API_FLUENT = 'fluent';
endp.ENDPOINT_API_HFDM = 'hfdm'; // Forge Data

var _apis_data = {
  derivativeV2: {
    baseURL: '/derivativeservice/v2',
    itemURL: '/derivativeservice/v2/derivatives/:derivativeurn',
    manifestURL: '/derivativeservice/v2/manifest/:urn',
    thumbnailsURL: '/derivativeservice/v2/thumbnails/:urn' },

  derivativeV2_EU: {
    baseURL: '/derivativeservice/v2/regions/eu',
    itemURL: '/derivativeservice/v2/regions/eu/derivatives/:derivativeurn',
    manifestURL: '/derivativeservice/v2/regions/eu/manifest/:urn',
    thumbnailsURL: '/derivativeservice/v2/regions/eu/thumbnails/:urn' },

  modelDerivativeV2: {
    baseURL: '/modelderivative/v2/',
    itemURL: '/modelderivative/v2/designdata/:urn/manifest/:derivativeurn',
    manifestURL: '/modelderivative/v2/designdata/:urn/manifest',
    thumbnailsURL: '/modelderivative/v2/designdata/:urn/thumbnail' },

  fluent: {
    baseURL: '/modeldata',
    itemURL: '/modeldata/file/:derivativeurn',
    manifestURL: '/modeldata/manifest/:urn',
    thumbnailsURL: '/derivativeservice/v2/thumbnails/:urn',
    cdnURL: '/cdn',
    cdnWS: '/cdnws'
    //cdnRedirectURL: '/cdnurl', //There is no separate CDN endpoint currently
  },
  hfdm: {
    baseURL: '',
    itemURL: '/derivatives/:derivativeurn',
    manifestURL: '/manifest/:urn',
    thumbnailsURL: '/thumbnails/:urn' } };



var _endpoint = '';
var _api = endp.ENDPOINT_API_DERIVATIVE_SERVICE_V2;
var _useCredentials = false;
var _useCookie = false;
var _acmSession = '';

endp.HTTP_REQUEST_HEADERS = {};

/**
                                 * Sets the endpoint and api to be used to create REST API request strings.
                                 * @param {string} endpoint
                                 * @param {string} [api] - Possible values are derivativeV2, modelDerivativeV2
                                 */
endp.setEndpointAndApi = function (endpoint, api) {
  _endpoint = endpoint;
  if (api) {
    _api = api;
  }
};

/**
    * Returns the endpoint plus the api used to create REST API request strings.
    * Example: "developer.api.autodesk.com/modelderivative/v2"
    * @returns {string}
    */
endp.getEndpointAndApi = function () {
  return _endpoint + _apis_data[_api].baseURL;
};

/**
    * Returns the endpoint used to create REST API request strings.
    * Examples: "developer.api.autodesk.com"
    * @returns {string}
    */
endp.getApiEndpoint = function () {
  return _endpoint;
};

/**
    * @private
    * @returns {string}
    */
endp.getApiFlavor = function () {
  return _api;
};

/**
    * Returns the default shared resource CDN location.
    * For best performance (and to not overload our servers), this should
    * be replaced by a direct CloudFront url during initialization, by
    * calling the cdnRedirectUrl and looking at the result.
    */
endp.getCdnUrl = function () {
  return CDN_ROOT || (_endpoint ? _endpoint + _apis_data[_api].cdnURL : undefined);
};

endp.getCdnWebSocketEndpoint = function () {
  return _endpoint + (_apis_data[_api].cdnWS || '');
};

endp.setCdnUrl = function (url) {
  CDN_ROOT = url;
};

endp.getCdnRedirectUrl = function () {
  var redirect = _apis_data[_api].cdnRedirectURL;
  if (!redirect)
  return null;
  return _endpoint + redirect;
};

endp.setAcmSession = function (value) {
  _acmSession = value;
};

endp.getAcmSession = function () {
  return _acmSession;
};

/**
    * Returns a REST API request strings to be used to get the manifest of the provided urn.
    * Example: "developer.api.autodesk.com/modelderivative/v2/designdata/:urn/manifest"
    * @param {string | null} endpoint - When provided is used instead of the globally set endpoint.
    * @param {string} urn
    * @param {string} api - When provided is used instead of the globally set API flavor
    * @returns {string}
    */
endp.getManifestApi = function (endpoint, urn, api) {
  var url = endpoint || _endpoint;
  api = api || _api;
  url += _apis_data[api].manifestURL;
  // If urn is not provided we return same string that before for backward compatibility.
  urn = urn || '';
  url = url.replace(':urn', urn);
  return url;
};

/**
    * Returns a REST API request strings to be used to get a derivative urn.
    * Example: "developer.api.autodesk.com/modelderivative/v2/designdata/:urn/manifest/:derivativeUrn"
    * @param {string | null} endpoint - When provided is used instead of the globally set API endpoint.
    * @param {string} derivativeUrn
    * @param {string} api - When provided is used instead of the globally set API flavor
    * @returns {string}
    */
endp.getItemApi = function (endpoint, derivativeUrn, api) {
  var theApi = api || _api;
  var itemApi = (endpoint || _endpoint) + _apis_data[theApi].itemURL;
  // If urn is not provided we return same string that before for backward compatibility.
  derivativeUrn = derivativeUrn || '';
  var decodedUrn = decodeURIComponent(derivativeUrn);

  // Extract svf urn from item urn, needed when using model derivative.
  if (itemApi.indexOf(':urn') !== -1) {
    var parts = decodedUrn.split('/');
    var urn = parts[0] || '';
    urn = urn.split(':');
    urn = urn[urn.length - 1] || '';

    itemApi = itemApi.replace(':urn', urn);
  }

  if (theApi === endp.ENDPOINT_API_MODEL_DERIVATIVE_V2) {
    derivativeUrn = encodeURIComponent(decodedUrn);
  }

  itemApi = itemApi.replace(':derivativeurn', derivativeUrn);

  return itemApi;
};

/**
    * Returns a REST API request strings to be used to get the thumbnail for a specific urn.
    * Example: "developer.api.autodesk.com/modelderivative/v2/designdata/:urn/thumbnail"
    * @param {string | null} endpoint - When provided is used instead of the globally set endpoint.
    * @param {string} urn
    * @param {string} api - When provided is used instead of the globally set API flavor
    * @returns {string}
    */
endp.getThumbnailApi = function (endpoint, urn, api) {
  var thumbnailApi = (endpoint || _endpoint) + _apis_data[api || _api].thumbnailsURL;
  return thumbnailApi.replace(':urn', urn || '');
};

endp.getUseCredentials = function () {
  return _useCredentials;
};

endp.getDomainParam = function () {
  return this.getUseCredentials() && !isNodeJS() ? "domain=" + encodeURIComponent(_window.location.origin) : "";
};

endp.setUseCredentials = function (useCredentials) {
  _useCredentials = useCredentials;
};

endp.setUseCookie = function (useCookie) {
  _useCookie = useCookie;
};

endp.getUseCookie = function () {
  return _useCookie;
};

endp.initLoadContext = function (inputObj) {

  inputObj = inputObj || {};

  inputObj.auth = this.getUseCredentials();

  if (!inputObj.endpoint)
  inputObj.endpoint = this.getApiEndpoint();

  if (!inputObj.api)
  inputObj.api = this.getApiFlavor();

  if (!inputObj.headers)
  inputObj.headers = {};

  for (var p in this.HTTP_REQUEST_HEADERS) {
    inputObj.headers[p] = this.HTTP_REQUEST_HEADERS[p];
  }

  //This is done to avoid CORS errors on content served from proxy or browser cache
  //The cache will respond with a previously received response, but the Access-Control-Allow-Origin
  //response header might not match the current Origin header (e.g. localhost vs. developer.api.autodesk.com)
  //which will cause a CORS error on the second request for the same resource.
  var domainParam = this.getDomainParam();
  if (domainParam) {
    if (inputObj.queryParams) {
      inputObj.queryParams += "&" + domainParam;
    } else {
      inputObj.queryParams = domainParam;
    }
  }

  //shared geometry/material storage
  inputObj.otg_cdn = CDN_ROOT || this.getCdnUrl();
  inputObj.otg_ws = this.getCdnWebSocketEndpoint();

  return inputObj;
};

//TODO: Globals that need a better place
var _env; //formerly avp.env
export function getEnv() {
  return _env;
}
export function setEnv(env) {
  _env = env;
}

// Set viewer in offline mode if set to true. In offline mode, viewer would ignore all URNs in bubble JSON
// and assume the viewables are laid out in local file system path relative to the bubble.json.
var _offline = false;
export function isOffline() {
  return _offline;
}
export function setOffline(offline) {
  _offline = offline;
}

// Offline resource prefix specified by viewer consumer (e.g. IOS web view). Used as prefix to concatenate with
// each resource relative path to form the absolute path of each resource.
var _offlineResourcePrefix = "";
export function setOfflineResourcePrefix(prefix) {
  _offlineResourcePrefix = prefix;
}
export function getOfflineResourcePrefix() {
  return _offlineResourcePrefix;
}

export var endpoint = endp;

//For backwards compatibility until all code is converted to use
//the function from the endpoint instance.
export var initLoadContext = endp.initLoadContext.bind(endp);