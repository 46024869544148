import { Prefs3D, Prefs2D } from './PreferenceNames';
import { LocalStorage } from './LocalStorage';
import { getGlobal } from '../compat';

var _window = getGlobal();

/**
                            * Clears the local storage if a new profile is loaded that is not already in the browser's local storage.
                            * Example:
                            * 1. The AEC profile is loaded and some preferences are stored in local storage.
                            * 2. The Default profile is loaded, the preferences from the AEC profile will be removed from the local storage.
                            * @param {string} name - profile name
                            * @param {Autodesk.Viewing.Preferences} prefs - viewer preferences
                            * @private
                            */
function setLocalStorage(name, prefs) {
  if (LocalStorage.isSupported()) {
    var profileStorageId = "Autodesk.Viewing.ProfileName";
    var profileName = _window.localStorage.getItem(profileStorageId);
    if (profileName !== name) {
      LocalStorage.setItem(profileStorageId, name);
      // Remove all of the preferences stored in Local storage.
      prefs.clearWebStorage();
    }
  }
}

/**
   * Profiles encapsulate viewer settings, extensions to unload, and extensions to load.
   * 
   * The `profileSettings.settings` parameter will override the existing  {@link Autodesk.Viewing.Private.Preferences|preferences} upon calling the {@link Autodesk.Viewing.Profile#apply|apply} method.
   * The `profileSettings.extensions.load` and `profileSettings.extensions.unload` arrays are used to load and unload extensions.
   * Make sure to set the profile by using the {@link Autodesk.Viewing.Viewer3D#setProfile} method.
   * 
   * @example
   * const profileSettings = {
   *    name: "mySettings",
   *    description: "My personal settings.",
   *    settings: {
   *        ambientShadows: false,
   *        groundShadows: true
   *    }
   *    persistent: ['ambientShadows'],
   *    extensions: {
   *        load: ["Autodesk.BimWalk"],   // Extensions to load
   *        unload: ["Autodesk.ViewCubeUi"]  // Extensions to unload and to not load
   *    }
   * };
   * const profile = new Autodesk.Viewing.Profile(profileSettings);
   * @constructor
  
   * @param {ProfileSettings} profileSettings - the profile settings. 
   * @alias Autodesk.Viewing.Profile
   */
export function Profile(profileSettings) {
  if (!profileSettings) return;
  var av = Autodesk.Viewing;
  var parentProfileSettings = av.ProfileSettings.Default;
  var prefsToOverride = [];

  // Use "Custom" as the profile name if a name is not passed in with the settings object
  this.name = profileSettings.hasOwnProperty('name') ? profileSettings.name : 'Custom';
  this.label = profileSettings.label;
  this.description = profileSettings.description;

  // Check which preferences we want to store.
  this.persistent = Array.isArray(profileSettings.persistent) ?
  profileSettings.persistent :
  parentProfileSettings.persistent;

  // Assign the default profile
  this.settings = Object.assign({}, parentProfileSettings.settings);

  if (profileSettings.hasOwnProperty('settings')) {
    var settings = profileSettings.settings;
    prefsToOverride = Object.keys(settings);
    // merge the passed in profile with the default profile
    this.settings = Object.assign(this.settings, settings);
  }

  var extsToLoad = [];
  var extsToUnload = [];

  // Get the extensions that need to be loaded and unloaded
  if (profileSettings.hasOwnProperty('extensions')) {
    var toLoad = profileSettings.extensions.load;
    var toUnload = profileSettings.extensions.unload;
    extsToLoad = toLoad ? toLoad.slice() : extsToLoad;
    extsToUnload = toUnload ? toUnload.slice() : extsToUnload;
  }

  this.extensions = {
    load: extsToLoad,
    unload: extsToUnload };


  /**
                             * Applies the profile's settings to the viewer preferences.
                             * To make the viewer react to the updated preferences please reference {@link Autodesk.Viewing.Viewer3D#setProfile}.
                             * @param {Autodesk.Viewing.Private.Preferences} prefs - preferences instance.
                             * @alias Autodesk.Viewing.Profile#apply
                             */
  this.apply = function (prefs) {
    if (!prefs) return false;

    // Clear the preferences from local storage if the profile changed.
    setLocalStorage(this.name, prefs);
    var settings = this.settings;
    var viewerDefined = [av.ProfileSettings.Default.name, av.ProfileSettings.AEC.name];
    var prefs3d = Object.values(Prefs3D);
    var prefs2d = Object.values(Prefs2D);
    for (var name in settings) {
      if (settings.hasOwnProperty(name)) {
        var value = settings[name];
        // Ignore metadata if the profile is a custom one (not the ProfileSettings.AEC or the DefaultProfile Settings.)
        var tags =
        prefsToOverride.indexOf(name) !== -1 && viewerDefined.indexOf(this.name) === -1 ?
        ['ignore-producer'] :
        [];
        if (prefs3d.indexOf(name) !== -1) {
          tags.push('3d');
        } else if (prefs2d.indexOf(name) !== -1) {
          tags.push('2d');
        } else {
          tags.push('2d');
          tags.push('3d');
        }

        // If the preference is not in the persistent array then add the no-storage tag.
        if (this.persistent.indexOf(name) === -1) {
          tags.push('no-storage');
        }

        var prefValue = prefs.get(name);
        if (prefValue !== undefined) {
          // Add tags to the preference even if the value did not change
          prefs.addTags(name, tags);
          // Fire an event if the preference value is being changed by the profile
          if (prefValue !== value) {
            prefs.set(name, value);
          }
        } else {
          // Add the preference and fire the event.
          prefs.add(name, value, tags, true);
        }
      }
    }

    return true;
  };
}